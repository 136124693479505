import  React, {useEffect} from "react";
import Box from "@mui/material/Box";
import { AiFillDelete } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import StateContext from "../../contexts/ContextProvider";

import Modal from "@mui/material/Modal";

import "../../pages/view.css";
import { useContext } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 250,
  bgcolor: "background.paper",
  border: "2px solid redious #000",
  boxShadow: 24,
  p: 4,
};

const ConfirmModal = (itemId) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function mainPage(e) {
    navigate("/manageItem");
  }

  const { setValue } = useContext(StateContext);

  const itemDelete = (itemId) => {
    const id = Object.values(itemId);
    console.log(id)
    fetch(`https://api.orbitmart.co.in/api/v1/product/${id[0]}`, {
      method: "DELETE",
    })
      .then(async (response) => {
        const data = await response.json();
        setValue(200);
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        console.log("Delete successful");
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
      window.location.reload();
    handleClose();
    mainPage();
  };

  useEffect(() => {
    itemDelete
  },[]);

  return (
    <div>
      <button type="button" onClick={handleOpen}>
        <AiFillDelete />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="!rounded ">
          <div
            style={{ color: "red", display: "flex" }}
            className="font-2xl leading-tight text-6xl mt-0 mb-2  flex space-x-2 justify-center"
          >
            <RiErrorWarningLine />
          </div>

          <h1 class="font-bold leading-tight  flex space-x-2 justify-center align-center text-xl mt-0 mb-0 text-black-600">
            Are you sure?
          </h1>

          <h4 class="flex space-x-2 justify-center aline-center">
            you won't be able to revert this!
          </h4>

          <br />
          <div className="flex space-x-2 justify-center items-center m-0 p-0">
            <button type="button"   className="backButton !ml-0" onClick={handleClose}>
              Cancel
                </button>
            <button type="button" className="addButton "   onClick={() => itemDelete(itemId).then(() => {
      window.location.reload();
    })}>
              Yes,delete it!
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
