import React, { useContext, useState, useEffect } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
import axios from "axios";
// import { itemsData, contextMenuItems, itemsGrid } from "../../assets/dummy";
// import { itemsData, contextMenuItems, itemsGrid } from "../../assets/dummy";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  contextMenuItems,
  itemsGrid,
  itemsData,
} from "./config/MerchantConfig";
import { BsFillPencilFill } from "react-icons/bs";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import {BiSearch} from "react-icons/bi";
import DeleteModel from "./DeleteModel";
import "./style.css";
import StateContext from "../../contexts/ContextProvider";

//Material Ui
import {
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import ProductToggle from "./ProductToggle";
import ToggleSwitch from "./ToggleSwitch";
import {
  FirstPage,
  FirstPageOutlined,
  LastPage,
  LastPageOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";
import {
  Button,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { borderRadius } from "@mui/system";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    // borderCollapse: "collapse", 
    border: "none",
  },
  cell: {
    padding: "8px", 
    textAlign: "start",
    // background: 'red',
    // border: "1px solid " + theme.palette.divider,
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "15px", 
    textAlign: "start",
  },
}));

const ManageItem = () => {
  const classes = useStyles();
  const { token } = useContext(StateContext);
  const { merId } = useContext(StateContext);
  const { value } = useContext(StateContext);
  const { setValue } = useContext(StateContext);
  const { setViewData } = useContext(StateContext);
  const [loading, setLoading] = useState(false);
  //MaterialUi
  const [data, setData] = useState([]); // Data to be displayed in the table
  const [totalRecords, setTotalRecords] = useState(0); // Total number of records available
  const savedPage = parseInt(sessionStorage.getItem('currentPageNumber')) || 0;
  const [currentPage, setCurrentPage] = useState(savedPage);
  const [pageSize, setPageSize] = useState(10);
  const totalPages = Math.ceil(data?.totalRecords / pageSize); // Number of records to be displayed per page

  const random = 190;

  const [tDatas, setTDatas] = useState([""]);
  const [searchQuery, setSearchQuery] = useState('');
console.log(data?.data, "data?.data")
  const filteredData = data?.data?.filter((item) => {
    return item?.itemName.toLowerCase().includes(searchQuery.toLowerCase())
  });
  console.log(filteredData, "Sdsf", data?.data)

  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ["Search"];
  const editing = {};

  function fetchData(page) {
    const pageShowUse = localStorage.getItem('pagination') || 0;
    let pageShow = sessionStorage.getItem('currentPageNew') || 1;
    const id = merId;
    console.log(id)
    if (merId) {
      setLoading(true);
      const bodyData = {
        id: "",
        search: ""
      };
      const headers = {
        "x-token": JSON.parse(sessionStorage.getItem("merchant_login_data"))
          .data.token,
      };
      axios
        .get(
          `https://api.orbitmart.co.in/api/v1/product/byMerchant/${id}?page=${pageShow}`,
          // `https://api.orbitmart.co.in/api/v1/product/getBy/Merchant/${id}?page=${pageShow}`,
          // bodyData,
          {
            headers,
          }
        )
        .then((res) => {
          setTDatas(res.data);
          setData(res.data);
          setTotalRecords(res.totalRecords);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.message);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
    }
  }


//   function fetchData(page) {
//     console.log(currentPage, "currentPage....", page, "page");
//     let pageShow = page + 1;
//     setRefreshPage(pageShow);
//     const id = merId;
//     const fixId = "62eb910ee18b285f897d026e";
//     console.log(JSON.parse(sessionStorage.getItem("merchant_login_data")));
//     if (merId) {
//         setLoading(true);
//         const headers = {
//             "x-token": JSON.parse(sessionStorage.getItem("merchant_login_data")).data.token,
//         };

//         try {
//             const response = axios.get(
//                 `https://api.orbitmart.co.in/api/v1/product/byMerchant/${id}?page=${pageShow}`,
//                 { headers }
//             );

//             setTDatas(response.data);
//             setData(response.data);
//             setTotalRecords(response.totalRecords);
//             setTimeout(() => {
//                 setLoading(false);
//             }, 500);
//         } catch (error) {
//             console.error(error);
//             console.error(error.message);
//             setTimeout(() => {
//                 setLoading(false);
//             }, 500);
//         }
//     }
// }

  const itemId = tDatas.data;

  const navigate = useNavigate();

  const addPage = () => {
    navigate("/manageItem/add");
  };

  // function gridActions(itemId) {
  //   return (
  //     <div style={{ display: "flex", justifyContent: "space-between" }}>
  //       <h1
  //         style={{ color: "#8be78b", display: "flex" }}
  //         className="font-small leading-tight text-xl mt-0 mb-2 "
  //       >
  //         <Link to={`/manageItem/edit/${itemId._id}`}>
  //           <BsFillPencilFill onClick={() => itemEdit(itemId)} />
  //         </Link>
  //       </h1>
  //       <h1
  //         style={{ color: "#03c9d7", display: "flex" }}
  //         className="font-small leading-tight text-xl mt-0 mb-2 "
  //       >
  //         <Link to={`/manageItem/view/${itemId._id}`}>
  //           <FiEye onClick={() => itemView(itemId)} />
  //         </Link>
  //       </h1>
  //       <button
  //         style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
  //         className="font-small leading-tight text-xl mt-0 mb-2 "
  //       >
  //         <DeleteModel itemId={itemId._id} />
  //       </button>
  //     </div>
  //   );
  // }
 function gridActions(itemId) {
    return (
      <div className="flex items-center gap-2">
        <h1
          style={{ color: "#8be78b", display: "flex" }}
          className="font-small leading-tight text-xl mt-0 mb-2 "
        >
          <Link to={`/manageItem/edit/${itemId._id}`}>
            <BsFillPencilFill onClick={() => itemEdit(itemId)} />
          </Link>
        </h1>
        <h1
          style={{ color: "#03c9d7", display: "flex" }}
          className="font-small leading-tight text-xl mt-0 mb-2 "
        >
          <Link to={`/manageItem/view/${itemId._id}`}>
            <FiEye onClick={() => itemView(itemId)} />
          </Link>
        </h1>
 
        <button
          style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
          className="font-small leading-tight text-xl mt-0 mb-2 "
        >
          <DeleteModel itemId={itemId._id} />
        </button>
      </div>
    );
  }

  function gridStatus(item) {
    return (
      <div>
        <ToggleSwitch item={item} />
      </div>
    );
  }
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    console.log(e.target.value);
    setCurrentPage(0); // Reset to the first page whenever a new search is made
  };

  useEffect(
    (page) => {
      if (merId) {
        fetchData(page);
        setValue(200)
      }
    },
    [value, merId]
  );

  const itemEdit = (itemId) => {
    const editId = itemId._id;
    setViewData(itemId);
  };

  const itemView = (itemId) => {
    const editId = itemId._id;
    setViewData(itemId);
  };

  function gridImage(itemId) {
    return (
      <img
        className="rounded-xl h-20 md:ml-3"
        src={itemId.image}
        alt="order-item"
      />
    );
  }

  // function handlePageChange(data) {
  //   const selectedPage = data.selected + 1;
  //   setCurrentPage(selectedPage);
  //   fetchData(selectedPage);
  // }

  const handleChangePage = (event, newPage) => {
    console.log(newPage, "change page",)
    // localStorage.setItem("currentP", newPage)
    setCurrentPage(newPage);
    sessionStorage.setItem("currentPageNew", newPage+1)
    console.log(currentPage, "current page", newPage, "new page")
    fetchData(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const CustomPaginationActions = (props) => {
    const { count, page, rowsPerPage, onPageChange } = props;
    const handleFirstPageButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, 0);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    const handleLastPageButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    const handleNextButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page + 1);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    const handleBackButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page - 1);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
    useEffect(() => {
      sessionStorage.setItem('currentPageNumber', currentPage);
    }, [currentPage]);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPageOutlined />
        </Button>
        <Button onClick={handleBackButtonClick} disabled={page === 0}>
          <NavigateBefore />
        </Button>
        <span className="myPage">{currentPage + 1}</span>
        <Button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <NavigateNext />
        </Button>
        <Button
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <LastPageOutlined />
        </Button>
      </div>
    );
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    // style={{border: '3px solid red', background: 'blue'}}
    <div className="mx-2 my-0 md:m-10 mt-24 px-2 pt-1 md:px-10 bg-white rounded-3xl">
      <span class="flex space-x-2 m-4 justify-end">
        <Button onClick={() => addPage()} variant="outlined" className="newRecordBtn" color="inherit">
          New Record
        </Button>
      </span>
      <AppBar
        position="static"
        style={{ backgroundColor: "#003c7e", borderRadius: "20px" }}
      >
        <Toolbar style={{justifyContent: "space-between" }}>
          <Typography variant="h6" className="hidden sm:block">
            Products List ({data?.totalRecords})
          </Typography>
          <span class="flex space-x-2 m-4 justify-end" style={{background: 'white' ,borderRadius: '6px'}}>
            <input
            style={{padding: "4px 10px", outline: 'none', borderRadius: '6px 0 0 6px', color: 'black'}}
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <BiSearch style={{ fontSize: '25px', color: 'gray', cursor: 'pointer', margin: 'auto', paddingRight: '5px' }} />   {/*  this is the search icon */}
          </span>
        </Toolbar>
      </AppBar>
      <TableContainer className={filteredData?.length > 0 ? "table-container" : ""}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellRow}>Image</TableCell>
              <TableCell className={classes.cellRow}>Item Name</TableCell>
              <TableCell className={classes.cellRow}>Item Price</TableCell>
              <TableCell className={classes.cellRow}>Stock Quantity</TableCell>
              <TableCell className={classes.cellRow}>Unit Quantity</TableCell>
              <TableCell className={classes.cellRow}>Status</TableCell>
              <TableCell className={classes.cellRow}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.length > 0 ? (
              filteredData.map((item) => (
                <TableRow key={item._id}>
                  <TableCell className={classes.cell} style={{width: '150px'}}>
                    <img
                      src={item?.image}
                      alt="image"
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundSize: 'cover',
                        height: "100px",
                        borderRadius: "5px",
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.cell}>{item?.itemName}</TableCell>
                  <TableCell className={classes.cell}>{item?.customerPrice.toFixed(2)}</TableCell>
                  <TableCell className={classes.cell}>{item?.stockQuantity}</TableCell>
                  <TableCell className={classes.cell}>{item?.unitQuantityId?.title}</TableCell>
                  <TableCell className={classes.cell}>{gridStatus(item)}</TableCell>
                  <TableCell className={classes.cell}>{gridActions(item)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {data?.totalRecords > 0 ? (
          <TablePagination
            count={data?.totalRecords}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10]}
            showFirstButton={true}
            showLastButton={true}
            ActionsComponent={CustomPaginationActions}
          />
        ) : null}

        {/* {console.log(CurrentpageShow,"currentPage")} */}
      </TableContainer>
    </div>
  );
};
export default ManageItem;
