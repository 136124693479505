import React, { useState, useContext, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ImCross } from "react-icons/im";
import axios from "axios";
import StateContext from "../../contexts/ContextProvider";
import { useEffect } from "react";
import blank from "../../assets/blank.jpg";
import { BsFillPencilFill } from "react-icons/bs";
import { MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
//Alert
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { Store } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";


const EditOffer = () => {
  // const { id } = useParams();
  const store = useSelector((state) => state);

  const navigate = useNavigate();

  const { viewData, setViewData } = useContext(StateContext);
  const id = store.offer.offer._id;
  console.log(id, "xsafesfefesfsefsfsfiddddddddddddddd")
  const { merId } = useContext(StateContext);
  const { token } = useContext(StateContext);

  console.log(viewData, "12345555555555");
  const [data, setData] = useState(store.offer.offer);
  const [tDatas, setTDatas] = useState([""]);
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [pValue, setPValue] = useState(store.offer.offer.productId);
  const [loading, setLoading] = useState(true);
  // const [unitQuantitys, setUnitQuantitys] = useState([""]);
  // const [discountTypeSelect, setDiscountTypeSelect] = useState();

  function handleChange(e) {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  }

//  async function fetchProducts() {
//     const id = merId;
//     const headers = {
//       "x-token": token,
//     };
//    await axios
//       .get(`https://api.orbitmart.co.in/api/v1/product/byMerchant/${id}`, {
//         headers,
//       })
//       .then((res) => {
//         // console.log(res.data);
//         // setTDatas(res.data.data);
//         const responseData = res.data;
//         if (responseData && responseData.data) {
//           console.log(responseData.data, "hiamsnuas");
//           setTDatas(responseData.data);

//         }
//       })
//       .catch((err) => {
//         console.log(err);
//         console.log(err.message);
//       });
//   }

const fetchProducts = async () => {
  const id = merId;
  const headers = {
    "x-token": token,
  };

  try {
    const response = await axios.get(`https://api.orbitmart.co.in/api/v1/product/byMerchant/${id}`, {
      headers,
    });

    const responseData = response.data;
    if (responseData && responseData.data) {
      console.log(responseData?.data, "hiamsnuas");
      setTDatas(responseData.data);
      setLoading(false); 
    }
  } catch (err) {
    console.log(err);
    console.log(err.message);
    setLoading(false); 
  }
};




  // function fetchSpecificProducts() {
  //   // debugger;
  //   const id = viewData.unitQuantityId;
  //   const headers = {
  //     "x-token": token,
  //   };
  //   axios
  //     .get(`https://api.orbitmart.co.in/api/v1/product/byUnitQuantity/${id}`, {
  //       headers,
  //     })
  //     .then((res) => {
  //       // debugger;
  //       console.log(res);
  //       setTDatas(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  const [mDatas, setMDatas] = useState([]);

  const handleId = (e) => {
    debugger;
    let index = e.target.selectedIndex;
    let arr = e.target.childNodes[index].id;
    if (document.getElementById(arr).style.backgroundColor === "skyblue") {
      document.getElementById(arr).style.backgroundColor = "";
      setMDatas(data.filter((item) => item !== arr));
    } else {
      document.getElementById(arr).style.backgroundColor = "skyblue";
      setMDatas((data) => [...data, arr]);
    }
    // console.log(data);
  };

  // console.log(data);

  useEffect(() => {
    fetchProducts()
    // fetchSpecificProducts();
    // fetchUnitQuantity();
  }, []);

  // function fetchUnitQuantity() {
  //   axios
  //     .get(`https://api.orbitmart.co.in/api/v1/unitQuantity`)
  //     .then((res) => {
  //       console.log(res.data);
  //       setUnitQuantitys(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // }

  function mainPage() {
    navigate("/offers");
  }

  function postData() {
    // debugger;
    const finalData = {
      offerType: data.offerType,
      name: data.name,
      description: data.description,
      offerDiscount: data.offerDiscount,
      discountType: data.discountType,
      productId: pValue,
      unitQuantityId: data.unitQuantityId,
    };
    axios
      .put(`https://api.orbitmart.co.in/api/v1/offer/${id}`, finalData)
      .then((res) => {
        console.log(res);
        setAlert(true);
        setTimeout(() => {
          mainPage();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setTimeout(() => {
          mainPage();
        }, 1000);
      });
  }

  function gridImage(tDatas) {
    return (
      <div>
        <img
          className="rounded-circle h-10 w-10 md:ml-0"
          src={tDatas.image}
          alt="img"
        />
        <span style={{ marginLeft: 25 }}>{tDatas.itemName}</span>
        <span style={{ marginLeft: 25 }}>{tDatas.itemPrice}</span>
      </div>
    );
  }

  function gridHeader() {
    return (
      <div className="header">
        <span style={{ marginLeft: 15, fontSize: 15 }} className="columnHeader">
          Image
        </span>
        <span style={{ marginLeft: 10, fontSize: 15 }} className="columnHeader">
          Product Name
        </span>
        <span style={{ marginLeft: 20, fontSize: 15 }} className="columnHeader">
          Price
        </span>
      </div>
    );
  }
  const fields = { value: "_id", text: "itemName" };
  // function onTagging() { }
  // (e) => {
  //     // set the current selected item text as class to chip element.
  //     e.setClass(e.defaultValues[fields.value]);
  // };
  console.log(data.productId, "sddddd")
  // setViewData((prev) => {
  //   return {...prev, productId:[...values]}
  // })
  console.log(store.offer.offer, "dhruv bhai thanks")
  // useEffect(() =>{console.log(setViewData, "dhruv bhai thanks")},[setViewData])
  return (
  <>   {loading ? <CircularProgress /> : 
  <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
    {alert ? (
      <Stack
        sx={{ width: "30%" }}
        style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
        spacing={2}
      >
        <Alert variant="filled" severity="success">
          Data Updated Successfully
        </Alert>
      </Stack>
    ) : null}
    {error ? (
      <Stack
        sx={{ width: "30%" }}
        style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
        spacing={2}
      >
        <Alert variant="filled" severity="error">
          Sorry, Data Cannot be Updated at this moment. Please try Again!
        </Alert>
      </Stack>
    ) : null}
    <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
      Edit Offers
    </h4>
    <div class="flex space-x-2 justify-end">
      <button
        type="button"
        class="backButton"
        style={{ width: "8%" }}
        onClick={() => mainPage()}
      >
        Back
      </button>
    </div>
    <form>
      <div class="grid xl:grid-cols-3  m:gap-2">
        <div class="relative z-0 p-2 w-full mb-6 group">
          <label>Offer Types</label>
          <select
            class="form-select 
w-full
px-3
py-1.5
text-base
font-normal
text-gray-700
bg-white bg-clip-padding bg-no-repeat
border border-solid border-gray-300
rounded
transition
ease-in-out
m-0
focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            aria-label="Default select example"
            name="offerType"
            value={data.offerType}
            onChange={handleChange}
          >
            <select>Select Deal</select>
            <option value="TODAY">Today's Deal</option>
            <option value="BEST">Best Deals</option>
          </select>
        </div>

        <div class="relative z-0 p-2 w-full mb-6 group">
          <label>Offer Name</label>
          <input
            type="text"
            class="form-control
 block
 w-full
 px-3
 py-1.5
 text-base
 font-normal
 text-gray-700
 bg-white bg-clip-padding
 border border-solid border-gray-300
 rounded
 transition
 ease-in-out
 m-0
 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            name="name"
            value={data.name}
            onChange={handleChange}
          />
        </div>

        <div class="relative z-0 p-2 w-full mb-6 group">
          <label>Description</label>
          <input
            type="text"
            class="form-control
 block
 w-full
 px-3
 py-1.5
 text-base
 font-normal
 text-gray-700
 bg-white bg-clip-padding
 border border-solid border-gray-300
 rounded
 transition
 ease-in-out
 m-0
 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            name="description"
            value={data.description}
            onChange={handleChange}
          />
        </div>

        <div class="relative z-0 p-2 w-full mb-6 group">
          <label>Discount Type</label>
          <select
            name="discountType"
            value={data.discountType}
            onChange={handleChange}
            class="form-select 
w-full
px-3
py-1.5
text-base
font-normal
text-gray-700
bg-white bg-clip-padding bg-no-repeat
border border-solid border-gray-300
rounded
transition
ease-in-out
m-0
focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            aria-label="Default select example"
          >
            <option selected>Select Value</option>
            {/* <select>Select Value</select> */}
            <option value="PERCENT">Percentage</option>
            <option value="FIXED">Fixed</option>
          </select>
        </div>

        <div class="relative z-0 p-2 w-full mb-6 group">
          <label>Offer Discount</label>
          <input
            type="number"
            class="form-control
 block
 w-full
 px-3
 py-1.5
 text-base
 font-normal
 text-gray-700
 bg-white bg-clip-padding
 border border-solid border-gray-300
 rounded
 transition
 ease-in-out
 m-0
 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            name="offerDiscount"
            value={data.offerDiscount}
            onChange={handleChange}
            placeholder="Enter Discount"
          />
        </div>
        {console.log(data.productId, data, "here i got the products", pValue, "tdatas", tDatas)}
        <div class="relative z-0 p-2 w-full mb-6 group">
          <label>Products</label>
          <MultiSelectComponent
            id="chip-customization"
            placeholder="Select a Product"
            dataSource={tDatas}
            itemTemplate={gridImage}
            headerTemplate={gridHeader}
            value={pValue}
            fields={fields}
            mode="Box"
            onChange={(e) => setPValue(e.target.value)}
          // tagging={onTagging}
          />
          {/* {pValue != null ? null : (
       <p class="peer-invalid:visible text-red-700 font-light">
         Please Select the Products Again
       </p>
     )} */}
        </div>
      </div>
    </form>
    <div class="flex space-x-2 justify-end">
      <button
        type="button"
        onClick={postData}
        class="addButton"
        style={{ width: "8%" }}
      >
        Update
      </button>
    </div>
  </div>
    }</>
 

    
  );
};

export default EditOffer;
