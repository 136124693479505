import React, { useEffect, useState, useContext } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection,
  Edit,
  Sort,
  Filter,
  Resize,
  ContextMenu,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
import { contextMenuItems, ordersGrid } from "./config/BannerConfig";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../view.css";
import axios from "axios";
import StateContext from "../../contexts/ContextProvider";
import DeleteBannerModel from "./DeleteBannerModel";
import { BiSearch } from "react-icons/bi"
import {
  Alert,
  CircularProgress,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { BsFillPencilFill } from "react-icons/bs";
import {
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import {
  FirstPageOutlined,
  InsertEmoticonOutlined,
  LastPageOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse", // For proper border spacing
    border: 'none'
  },
  cell: {
    // borderBottom: "1px solid " + theme.palette.divider,
    padding: "8px",
    textAlign: "center", 
    // border: "1px solid " + theme.palette.divider
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "15px", // Add padding for cell content
    textAlign: "center", // Adjust text alignment if needed
  }
}));

const Banner = () => {
  const classes = useStyles();
  const [tDatas, setTDatas] = useState([""]);
  // const [tDatas, setTDatas] = useState({data:[]})
  const [alert, setAlert] = useState(false);
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [pageSize, setPageSize] = useState(5);

  const { value } = useContext(StateContext);
  const { setValue } = useContext(StateContext);
  const { setBanner } = useContext(StateContext);
  const [loading, setLoading] = useState(false);
  const { merId } = useContext(StateContext);

  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ["Search"];

  const editing = { allowEditing: true };

  const random = 193;
  const [searchQuery, setSearchQuery] = useState('');

  // const fetchData = () => {
  //   const id = merId;
  //   setLoading(true);
  //   fetch(`https://api.orbitmart.co.in/api/v1/banner/user/${id}`)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setTDatas(data);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 500);
  //     });
  // };
  const fetchData = async () => {
    const id = merId;
    setLoading(true);
  
    try {
      const response = await fetch(`https://api.orbitmart.co.in/api/v1/banner/user/${id}`);
      const data = await response.json();
      setTDatas(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };
  
  useEffect(() => {
    if(merId) {
      fetchData();
      setValue(201);
    }
  }, [value, merId]);

  console.log(tDatas.data, "tDatas")



  const filteredData =  tDatas.data?.filter((item) => {
    return item.bannerName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const itemId = tDatas.data;
  console.log(tDatas.data);
  console.log("tDatas.data abc", tDatas.data);

  if (itemId != undefined) {
    var size = Object.keys(itemId).length;
  }
  console.log(size);
  const navigate = useNavigate();
  const addPage = () => {
    navigate("/banner/add");
  };

  // function gridAction(itemId) {
  //   return (
  //     <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
  //       <h1
  //         style={{ color: "#8be78b", display: "flex" }}
  //         className="font-small leading-tight text-xl mt-0 mb-2 "
  //       >
  //         <Link
  //           style={{ marginRight: "auto" }}
  //           to={`/banners/edit/${itemId._id}`}
  //         >
  //           <BsFillPencilFill onClick={() => setBanner(itemId)} />
  //         </Link>
  //       </h1>
  //       <button
  //         style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
  //         className="font-small leading-tight text-xl mt-0 mb-2 "
  //       >
  //         <DeleteBannerModel itemId={itemId._id} />
  //       </button>
  //     </div>
  //   );
  // }

  function gridAction(itemId) {
    return (
      <div className="flex items-center justify-center gap-2">
        <Link to={`/banners/edit/${itemId._id}`} className="flex items-center">
          <h1
            style={{ color: "#8be78b" }}
            className="font-small leading-tight text-xl mt-0 mb-2"
          >
            <BsFillPencilFill onClick={() => setBanner(itemId)} />
          </h1>
        </Link>
        <button
          style={{ color: "#ff5c8e" }}
          className="font-small leading-tight text-xl mt-0 mb-2"
        >
          <DeleteBannerModel itemId={itemId._id} />
        </button>
      </div>
    );
  }

  function gridImage(itemId) {
    return (
      <div>
        <img
          className="rounded-xl h-20"
          src={itemId.coverPhoto[0]}
          alt="order-item"
        />
      </div>
    );
  }

  const currentPageData = filteredData?.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize
  );


  const handleChangePage = (event, newPage) => {
    console.log(newPage, "change page",)
    // localStorage.setItem("currentP", newPage)
    setCurrentPage(newPage);
    sessionStorage.setItem("currentPageNew", newPage+1)
    console.log(currentPage, "current page", newPage, "new page")
    fetchData(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const CustomPaginationActions = (props) => {
    const { count, page, rowsPerPage, onPageChange } = props;
    const handleFirstPageButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, 0);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    const handleLastPageButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    const handleNextButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page + 1);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    const handleBackButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page - 1);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
    useEffect(() => {
      sessionStorage.setItem('currentPageNumber', currentPage);
    }, [currentPage]);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPageOutlined />
        </Button>
        <Button onClick={handleBackButtonClick} disabled={page === 0}>
          <NavigateBefore />
        </Button>
        <span className="myPage">{currentPage + 1}</span>
        <Button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <NavigateNext />
        </Button>
        <Button
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <LastPageOutlined />
        </Button>
      </div>
    );
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    // m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl
    <div className="mx-2 my-0 md:m-10 mt-24 px-2 pt-1 md:p-10 bg-white rounded-3xl">
       <span class="flex space-x-2 m-4 justify-end">
            <Button
              className="newRecordBtn"
              onClick={() => addPage()}
              variant="outlined"
              color="inherit"
            >
              New Banner
            </Button>
          </span>
      <AppBar
        position="static"
        style={{ backgroundColor: "#003c7e", borderRadius: "20px" }}
      >
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography variant="h6" className="hidden sm:block">Banners ({tDatas?.data?.length})</Typography>
          <span class="flex space-x-2 m-4 justify-end" style={{background: 'white' ,borderRadius: '6px'}}>
            <input
            style={{padding: "4px 10px", outline: 'none', borderRadius: '6px 0 0 6px', color: 'black'}}
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <BiSearch style={{ fontSize: '25px', color: 'gray', cursor: 'pointer', margin: 'auto', paddingRight: '5px' }} />   {/*  this is the search icon */}
          </span>
        </Toolbar>
      </AppBar>
      <TableContainer className={currentPageData?.length > 0 ? "table-container" : ""}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellRow}>Banner Image</TableCell>
              <TableCell className={classes.cellRow}>Banner Name</TableCell>
              <TableCell className={classes.cellRow}>Location</TableCell>
              <TableCell className={classes.cellRow}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageData?.length > 0 ? (
              currentPageData?.map((item) => (
                <TableRow key={item._id} >
                  <TableCell style={{width: '170px'}} className={classes.cell}>{gridImage(item)}</TableCell>
                  <TableCell className={classes.cell}>{item.bannerName}</TableCell>
                  <TableCell className={classes.cell}>{item.locationName}</TableCell>
                  <TableCell className={classes.cell}>{gridAction(item)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {tDatas?.data?.length > 0 ? (
          <TablePagination
            count={tDatas?.data?.length}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5]}
            showFirstButton={true}
            showLastButton={true}
            ActionsComponent={CustomPaginationActions}
          />
        ) : null}
      </TableContainer>
    </div>
  );
};
export default Banner;
