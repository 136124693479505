import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import "../../pages/view.css";
import { useNavigate, useParams } from "react-router-dom";
import { useState,useEffect } from "react";
import axios from "axios";


const ViewVoucher = () => {
  const [view,setView]=useState();
  const [sucess ,setSucess]=useState([]);
  const { id } = useParams();
  console.log("golu",id)



  async function fetchData() {
  
    try{
      const result = await axios.get(`https://api.orbitmart.co.in/api/v1/voucher/${id}`)
      .then((result)=>{
        console.log(result.data)
        setSucess(result.data.data)
      })
     
     
     }
  catch(err){
    console.log(err)
  }
}
//  async function fetchData() {
  
//   try{
//     const result = await axios.get(`https://api.orbitmart.co.in/api/v1/voucher/${id}`)
//     .then((result)=>{
//       console.log(result.data)})
//     // console.log({result}) 
//     setSucess(result)
  
//    }
// catch(err){
//   console.log(err)
// }
     
      
//   }
  useEffect(() => {
   
      fetchData()
        // .then((res) => {
        //  console.log( res.data);
        // })
        // .catch((e) => {
        //   console.log(e.message);
        // });
     
  },[]);

  console.log("ccc",sucess)

  return (
    <div class="container">
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <div class="flex justify-end active">
          <div class="backIcon">
            <Link to="/voucher">
              <AiOutlineRollback />
            </Link>
          </div>
        </div>
        <div class="flex justify-center">
          <ul class="bg-white rounded-lg w-96 text-gray-900">
            <li class="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg">
              First Name : {sucess.name}
     
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
            actual amount:  {sucess.ActualAmount}
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
            discount amount:  {sucess.discountedMrp}</li>
             <li class="px-6 py-2 border-b border-gray-200 w-full">
             validity from: {sucess.validityFrom}
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
            validity to:  {sucess.validityUpto}
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
            zip code:  {sucess.pincode}
            </li>
            {/* <li class="px-6 py-2 w-full rounded-b-lg">Status : avb</li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ViewVoucher;
