import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Modal, Backdrop, Fade, Typography } from "@material-ui/core";
import { RiErrorWarningLine } from "react-icons/ri";
import axios from "axios";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  modalButtonContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  modalTitle: {
    textAlign: "center",
    color: "darkblue",
  },
  modalText: {
    textAlign: "center",
    marginTop: "10px",
    color: "black",
  },
  confirmButton: {
    backgroundColor: "#2196f3", // blue color
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1976d2", // darker blue color on hover
    },
  },
  cancelButton: {
    backgroundColor: "#f44336", // red color
    color: "#fff",
    "&:hover": {
      backgroundColor: "#d32f2f", // darker red color on hover
    },
  },
}));

function PaymentModal({ props }) {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    // debugger;
    console.log(props._id);
    axios
      .put(`https://api.orbitmart.co.in/api/v1/order/completed/${props._id}`)
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        window.location.reload();
      });
    setOpen(false);
  };

  const blueColor = "#003c7e";
  const containedButtonStyle = {
    color: "white",
    backgroundColor: blueColor,
    borderColor: blueColor,
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={containedButtonStyle}
        onClick={handleOpen}
      >
        Payment Received
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modalContainer}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.modalContent}>
            <div
              style={{ color: "red", display: "flex" }}
              className="font-2xl leading-tight text-6xl mt-0 mb-2  flex space-x-2 justify-center"
            >
              <RiErrorWarningLine />
            </div>
            <Typography className={classes.modalTitle} variant="h5">
              Comfirmation
            </Typography>
            <Typography className={classes.modalText} variant="body1">
              Have you Received the paymenet for this Order?
            </Typography>
            <div className={classes.modalButtonContainer}>
              <Button
                className={classes.cancelButton}
                variant="contained"
                onClick={handleClose}
              >
                No, Cancel
              </Button>
              <Button
                className={classes.confirmButton}
                variant="contained"
                onClick={handleConfirm}
              >
                Yes, Confirm
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default PaymentModal;
