import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { ImCross } from "react-icons/im";
import axios from "axios";
import "../view.css";
import { BsFillPencilFill } from "react-icons/bs";
import blank from "../../assets/blank.jpg";

//Alert
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

const EditManageItem = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);

  const mainPage = () => {
    navigate("/manageItem");
  };

  const { viewData } = useContext(StateContext);
  const { merchantId } = useContext(StateContext);
  console.log(merchantId, "merchantId");
  const { token } = useContext(StateContext);
  const { setValue } = useContext(StateContext);

  const [editValue, setEditValue] = useState(viewData);

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  const [category, setCategory] = useState([""]);
  const [groupCategory, setGroupCategory] = useState([""]);
  const [classification, setClassification] = useState([""]);
  const [subClassification, setSubClassification] = useState([""]);
  const [unitQuantitys, setUnitQuantitys] = useState([""]);
  const [brandName, setBrandName] = useState([""]);

  const [editData, setEditData] = useState([""]);

  const [customerPrice, setCustomerPrice] = useState();
  const [merchantPrice, setMerchantPrice] = useState();

  //Image
  const [selectedImages, setSelectedImages] = useState([]);

  const [cateId, setCateId] = useState();
  const [groId, setGroId] = useState();
  const [subClasId, setSubClasId] = useState();
  const [claseId, setClaseId] = useState();
  const [branId, setBranId] = useState();
  const [unitId, setUnitId] = useState();

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const [image, setImage] = React.useState("");
  // const [imgUpload, setImgUpload] = useState(false);

  console.log("image = ", image);

  function deleteImage(e) {
    const s = image.filter((item, index) => index != e);
    setImage(s);
  }

  function imageRemoval(e) {
    e.preventDefault;
    var image_x = document.getElementById("image_X");
    image_x.parentNode.removeChild(image_x);
    setEditValue.image("");
  }

  console.log(image);

  function changeImage() {
    console.log("xyz");
    return (
      <input
        accept="image/*"
        type="file"
        onChange={(e) => setSelectedImages(e.target.files[0])}
        class="form-control
block
w-full
px-3
py-1.5
text-base
font-normal
text-gray-700
bg-white bg-clip-padding
border border-solid border-gray-300
rounded
transition
ease-in-out
m-0
focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        id="image"
        name="image"
      />
    );
  }
  //Image

  const fetchCategories = async () => {
    // const id = merchantId;
    const id = editValue?.merchantTypeId?._id;
    try {
      const response = await axios.get(
        `https://api.orbitmart.co.in/api/v1/category/bymerchantTypeId/${viewData.categoryId.merchantTypeId}`
      );
      console.log(response.data);
      setCategory(response.data.data);
      const claseId = fetchClassifications();
    } catch (err) {
      console.log(err.message);
    }
  };

  function fetchGroupCategory() {
    axios
      .get(`https://api.orbitmart.co.in/api/v1/groupcategory`)
      .then((res) => {
        console.log(res.data);
        setGroupCategory(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function fetchUnitQuantity() {
    axios
      .get(`https://api.orbitmart.co.in/api/v1/unitQuantity`)
      .then((res) => {
        console.log(res.data);
        setUnitQuantitys(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function fetchBrandName() {
    axios
      .get(`https://api.orbitmart.co.in/api/v1/brandname`)
      .then((res) => {
        console.log(res.data);
        setBrandName(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setEditValue({ ...editValue, [name]: value });
  };

  const [actualPrice, setActualPrice] = useState();

  const [discountTypeSelect, setDiscountTypeSelect] = useState(
    editValue.discountType
  );

  const itemPriceFinal = () => {
    if (editValue.itemPrice != null) {
      const newValue1 =
        editValue.itemPrice -
        (editValue.orbitMartCommission / 100) * editValue.itemPrice;
      if (editValue.gst == null || discountTypeSelect == null) {
        console.log(newValue1);
        setActualPrice(newValue1);
      } else if (editValue.gst != null) {
        const newValue2 = newValue1 + (editValue.gst / 100) * newValue1;
        setActualPrice(newValue2);
        if (discountTypeSelect === "PERCENT") {
          if (editValue.customerDiscount != null) {
            const newValue3 =
              newValue2 - newValue2 * (editValue.customerDiscount / 100);
            console.log(newValue3);
            setActualPrice(newValue3);
          }
        } else if (discountTypeSelect === "FIXED") {
          const newValue3 = newValue2 - editValue.customerDiscount;
          console.log(newValue3);
          setActualPrice(newValue3);
        } else {
          setActualPrice(newValue2);
        }
      }
    }
  };

  function customerPriceFinal() {
    // debugger;
    setCustomerPrice(editValue.itemPrice);
    if (editValue.itemPrice != null) {
      if (
        editValue.discountType === "FIXED" &&
        editValue.customerDiscount != null
      ) {
        const newValue1 = editValue.itemPrice - editValue.customerDiscount;
        const newValue2 = newValue1 + (editValue.gst / 100) * newValue1;
        setCustomerPrice(newValue2);
      } else if (
        editValue.discountType === "PERCENT" &&
        editValue.customerDiscount != null
      ) {
        const newValue1 =
          editValue.itemPrice -
          editValue.itemPrice * (editValue.customerDiscount / 100);
        const newValue2 = newValue1 + (editValue.gst / 100) * newValue1;
        setCustomerPrice(newValue2);
      } else if (editValue.gst != null) {
        const newValue1 = (editValue.gst / 100) * editValue.itemPrice;
        const newValue2 = newValue1 + editValue.itemPrice;
        setCustomerPrice(newValue2);
      }
    }
  }

  function merchantPriceFinal() {
    // debugger;
    if (editValue.itemPrice != null) {
      const newValue1 =
        editValue.itemPrice -
        (editValue.orbitMartCommission / 100) * editValue.itemPrice;
      setMerchantPrice(newValue1);
      if (editValue.discountType === "FIXED") {
        const newValue2 = newValue1 - editValue.customerDiscount;
        const newValue3 = newValue2 + (editValue.gst / 100) * newValue2;
        setMerchantPrice(newValue3);
      } else if (editValue.discountType === "PERCENT") {
        const newValue4 = editValue.customerDiscount / 100;
        const newValue2 = newValue1 - newValue1 * newValue4;
        const newValue3 = newValue2 + (editValue.gst / 100) * newValue2;
        setMerchantPrice(newValue3);
      } else if (editValue.gst != null) {
        const newValue2 = (editValue.gst / 100) * newValue1;
        const newValue3 = newValue2 + newValue1;
        setMerchantPrice(newValue3);
      }
    }
  }

  useEffect(() => {
    fetchCategories();
    itemPriceFinal();
    customerPriceFinal();
    merchantPriceFinal();
    fetchUnitQuantity();
    fetchGroupCategory();
    fetchBrandName();
    valuePass();
  }, [
    editData,
    editValue,
    editValue.itemPrice,
    editValue?.categoryId?.title,
    editData.categoryId,
    editValue?.classificationId?.title,
    editData.classificationId,
  ]);

  console.log(editData);

  const fetchClassifications = async () => {
    const id = editData.categoryId;
    try {
      const response = await axios.get(
        `https://api.orbitmart.co.in/api/v1/classification/bycategoryId/${
          id ?? viewData.categoryId._id
        }`
      );
      setClassification(response.data.data);
      console.log(response.data.data);
      fetchSubClassifications();
    } catch (e) {
      console.log(e.message);
    }
  };
  const fetchSubClassifications = async () => {
    const id = editData.classificationId;
    try {
      const response = await axios.get(
        `https://api.orbitmart.co.in/api/v1/subclassification/byclassificationId/${
          id ?? viewData.classificationId._id
        }`
      );
      setSubClassification(response.data.data);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => fetchSubClassifications(), [editData.classificationId]);

  console.log(subClassification.length, "length: ");

  function valuePass() {
    if (editData.categoryId == undefined) {
      setCateId(editValue?.categoryId?._id);
    } else {
      setCateId(editData.categoryId);
    }

    if (editData.groupCategoryId == undefined) {
      setGroId(editValue?.groupCategoryId?._id);
    } else {
      setGroId(editData.groupCategoryId);
    }

    if (editData.classificationId == undefined) {
      setClaseId(editValue?.classificationId?._id);
    } else {
      setClaseId(editData.classificationId);
    }

    if (editData.subClassificationId == undefined) {
      setSubClasId(editValue?.subClassificationId?._id);
    } else {
      setSubClasId(editData.subClassificationId);
    }

    if (editData.brandNameId == undefined) {
      setBranId(editValue?.brandNameId?._id);
    } else {
      setBranId(editData.brandNameId);
    }
    // debugger;
    if (editData.unitQuantityId == undefined) {
      setUnitId(editValue?.unitQuantityId?._id);
    } else {
      setUnitId(editData.unitQuantityId);
    }
  }

  async function postData(e) {
    // debugger;
    const merId = id;
    const uploadImage = "https://api.orbitmart.co.in/api/v1/upload";
    const uploadData = `https://api.orbitmart.co.in/api/v1/product/${merId}`;
    const formData = new FormData();
    formData.append("Image", selectedImages[0]);
    axios
      .post(uploadImage, formData)
      .then((res) => {
        // debugger;
        console.log(res);
        const newimg = res.data.files;
        const coverPhoto = newimg[0];
        const finalData = {
          // merchantTypeId: merchantId,
          merchantTypeId: viewData.categoryId.merchantTypeId,
          domainType: editValue?.merchantTypeId?.domainType,
          // categoryId: editData.categoryId,
          categoryId: cateId,
          groupCategoryId: groId,
          classificationId: claseId,
          subClassificationId: subClasId,
          // groupCategoryId: editData.groupCategoryId,
          // classificationId: editData.classificationId,
          // subClassificationId: editData.subClassificationId,
          brandNameId: branId,
          unitQuantityId: unitId,
          unitPerQuantity: editValue.unitPerQuantity,
          // brandNameId: editData.brandNameId,
          // unitQuantity: editValue.unitQuantity,
          // unitPerQuantity: editData.unitPerQuantity,

          itemName: editValue.itemName,
          netWeight: editValue.netWeight,
          discountType: editValue.discountType,
          customerDiscount: editValue.customerDiscount,
          gst: editValue.gst,
          orbitMartCommissionType: editValue.orbitMartCommissionType,
          orbitMartCommission: editValue.merchantId.orbitMartCommission,
          itemPrice: editValue.itemPrice,
          customerPrice: customerPrice,
          merchantPrice: merchantPrice,
          description: editValue.description,
          stockQuantity: editValue.stockQuantity,
          bestOfferItem: true,
          image: coverPhoto,
        };
        console.log(finalData);

        e.preventDefault();
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(finalData),
        };
        fetch(uploadData, requestOptions)
          .then((response) => {
            // debugger;
            console.log(response);
            setAlert(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          })
          .catch((err) => {
            setError(true);
            console.log(err);
            setTimeout(() => {
              mainPage();
            }, 1000);
          });
      })
      .catch((err) => {
        setError(true);
        console.log(err);
        setTimeout(() => {
          mainPage();
        }, 1000);
      });
  }

  const filterSB = subClassification.filter(
    (data) => data._id !== editValue?.subClassificationId?._id
  );

  console.log(
    editValue?.subClassificationId?.title,
    "================================",
    filterSB
  );

  // const undefinedValue = "null";

  // useEffect(() => fetchClassifications, [undefinedValue = 'true status'])

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Data Updated Successfully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Data Cannot be Updated at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Edit Item :
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
          class="backButton"
        >
          Back
        </button>
      </div>

      <form method="post">
        <div class="grid xl:grid-cols-3  m:gap-2 form-group">
          <div class="relative z-0 p-2 w-full mb-6 group form-group">
            <label>Domain Type</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="domainType"
              name="domainType"
              value={editValue?.merchantTypeId?.domainType}
              disabled
              required="true"
            />
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group form-group">
            <label>Category</label>
            <select
              class="form-group form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="categoryId"
              value={editData.categoryId}
              onChange={handleChange}
            >
              <option value={editValue?.categoryId?._id}>
                {editValue?.categoryId?.title}
              </option>
              {category
                .filter((data) => data._id != editValue?.categoryId?._id)
                .map((typeget) => (
                  <option value={typeget._id}>{typeget.title}</option>
                ))}
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Group Category</label>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="groupCategoryId"
              value={editData.groupCategoryId}
              onChange={handleChange}
            >
              <option selected value={editValue?.groupCategoryId?._id}>
                {editValue?.groupCategoryId?.title}
              </option>
              {groupCategory
                .filter(
                  (data) => data.title != editValue?.groupCategoryId?.title
                )
                .map((typeget) => (
                  <option value={typeget._id} id={typeget._id}>
                    {typeget.title}
                  </option>
                ))}
            </select>
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Classification</label>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="classificationId"
              value={editData.classificationId}
              onChange={handleChange}
            >
              <option selected value={editValue?.classificationId?._id}>
                {editValue?.classificationId?.title}
              </option>
              {classification
                .filter(
                  (data) => data.title != editValue?.classificationId?.title
                )
                .map((typeget) => (
                  <option value={typeget._id} id={typeget._id}>
                    {typeget.title}
                  </option>
                ))}
            </select>
          </div>
          {console.log(
            viewData?.classificationId?.title,
            "viewData?.classificationId?.title"
          )}
          {console.log(
            subClassification.length > 0 ? editData?.subClassificationId : ""
          )}
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Sub Classification</label>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="subClassificationId"
              value={editData.subClassificationId}
              onChange={handleChange}
            >
              {/* <option value={editValue?.subClassificationId?._id}>
                {editValue?.subClassificationId?.title}
              </option>
              {subClassification
                .filter(data => data.title !== editValue?.subClassificationId?.title)
                .map((typeget) => (
                  <option value={typeget._id} id={typeget._id}>
                    {typeget.title}
                  </option>
                ))} */}

              {subClassification.length === 0 ? (
                <option value="">Classifications Not Available</option>
              ) : (
                <>
                  {editValue?.subClassificationId && (
                    <option value={editValue.subClassificationId._id}>
                      {editValue.subClassificationId.title}
                    </option>
                  )}
                  {subClassification
                    .filter(
                      (data) =>
                        data.title !== editValue?.subClassificationId?.title
                    )
                    .map((typeget) => (
                      <option key={typeget._id} value={typeget._id}>
                        {typeget.title}
                      </option>
                    ))}
                </>
              )}

              {/* {typeof editData.classificationId === "string" ? (
                subClassification
                  .filter(
                    (data) =>
                      data.title !== editValue?.subClassificationId?.title
                  )
                  .map((typeget) => (
                    <option
                      key={typeget._id}
                      value={typeget._id}
                      id={typeget._id}
                    >
                      {typeget.title}
                    </option>
                  ))
              ) : (
                <option selected value={editValue?.subClassificationId?._id}>
                  {editValue?.subClassificationId?.title}
                </option>
              )} */}
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Brand Name</label>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="brandNameId"
              value={editData.brandNameId}
              onChange={handleChange}
            >
              <option value={editValue?.brandNameId?._id}>
                {editValue?.brandNameId?.title}
              </option>
              {brandName
                .filter((data) => data.title != editValue?.brandNameId?.title)
                .map((typeget) => (
                  <option value={typeget._id} id={typeget._id}>
                    {typeget.title}
                  </option>
                ))}
            </select>
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Item Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="itemName"
              value={editValue.itemName}
              onChange={handleChanges}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Unit Quantity</label>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="unitQuantityId"
              value={editData.unitQuantityId}
              onChange={handleChange}
            >
              <option value={editValue?.unitQuantityId?._id}>
                {editValue?.unitQuantityId?.title}
              </option>
              {unitQuantitys
                .filter(
                  (data) => data.title != editValue?.unitQuantityId?.title
                )
                .map((typeget) => (
                  <option value={typeget._id}>{typeget.title}</option>
                ))}
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Net Weight (in GM)</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="netWeight"
              name="netWeight"
              value={editValue.netWeight}
              onChange={handleChanges}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Unit / Quantity</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="unitPerQuantity"
              value={editValue.unitPerQuantity}
              onChange={handleChanges}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Item Price</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="itemPrice"
              name="itemPrice"
              value={editValue.itemPrice}
              onChange={handleChanges}
            />
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Discount Type </label>
            <select
              name="discountType"
              value={editValue.discountType}
              onChange={handleChanges}
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option>Select Value</option>
              <option value="PERCENT">Percentage</option>
              <option value="FIXED">Fixed</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Discount (in % or Fix Value)</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="customerDiscount"
              value={editValue.customerDiscount}
              onChange={handleChanges}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>GST (in %)</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="gst"
              name="gst"
              value={editValue.gst}
              onChange={handleChanges}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Orbit Mart Commision Type </label>
            <select
              name="orbitMartCommissionType"
              value={editValue.orbitMartCommissionType}
              onChange={handleChanges}
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option>Select Value</option>
              <option value="PERCENT">Percentage</option>
              <option value="FIXED">Fixed</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Orbit Mart Commision</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="orbit"
              name="orbitMartCommission"
              value={editValue?.merchantId?.orbitMartCommission}
              onChange={(e) => {
                const { name, value } = e.target;
                setEditValue(prevState => ({
                  ...prevState,
                  merchantId: {
                    ...prevState.merchantId,
                    [name]: value
                  }
                }));
              }}
              // disabled
            />
          </div>

          {console.log(editValue, "editValue is not set")}

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Actual Price</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="actualSellingPrice"
              name="actualSellingPrice"
              value={actualPrice}
              disabled
            />
          </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Customer Price</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="customerPrice"
              name="customerPrice"
              value={Math.round(customerPrice)}
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Merchant Price</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="customerPrice"
              name="customerPrice"
              value={Math.round(merchantPrice)}
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Stock Quantity</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="stockQuantity"
              value={editValue.stockQuantity}
              onChange={handleChanges}
            />
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Description</label>
            <textarea
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="description"
              name="description"
              value={editValue.description}
              onChange={handleChanges}
            />
          </div>

          <div>
            <label>Product Image</label>
            <div class="relative mt-1 flex justify-center w-40">
              <div class=" absolute right-0 top-0 z-10 flex">
                <label
                  for="file-upload"
                  class="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                >
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={editValue.image} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="flex space-x-2 justify-end">
        <button
          type="submit"
          class="addButton"
          onClick={postData}
          style={{ width: "8%" }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default EditManageItem;
