import { configureStore } from '@reduxjs/toolkit';
import offerReducer from './offerSlice'; // Update the path accordingly

const store = configureStore({
  reducer: {
    offer: offerReducer,
    // ...other reducers if you have
  },
});

export default store;