import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import StateContext from "../contexts/ContextProvider";
import "./ToggleSwitch.css";

export default function ToggleSwitch({ data }) {
  console.log({data})
  const individuallyMerchant = data.distributorStatus
  const ADMIN_STATUS = data.adminActiveInactive;
  const DISTRIBUTOR_Status = data.distributorActiveInactive
  const { token, setProfileData } = useContext(StateContext);
  const storedState = localStorage.getItem("toggleState");
  const [state, setState] = useState(storedState ? JSON.parse(storedState) : data?.ActiveInactive);
  
  function handleChange(e) {
    const uploadData = "https://api.orbitmart.co.in/api/v1/auth/edit-profile";
    const finalData = {
      ActiveInactive: !state,
    };
    axios
      .put(uploadData, finalData, {
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
      })
      .then((res) => {
        console.log(res);
        setState(!state);
        localStorage.setItem("toggleState", JSON.stringify(!state));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // console.log({ state, key: data.ActiveInactive });
  console.log({ state });
console.log(state, individuallyMerchant, DISTRIBUTOR_Status, ADMIN_STATUS, "subham ko dikahnna hai saare status");

  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={state && individuallyMerchant && DISTRIBUTOR_Status && ADMIN_STATUS} onChange={() => handleChange()} />
      <span className="toggle-switch-slider"></span>
    </label>
  );
}
