import * as React from "react";
import "./vieworder.css";
import { Link } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import PaymentModal from "./PaymentModal";

const ViewOrder = () => {
  const [sucess, setSucess] = useState([]);
  const [sucesscommition, setSucessCommition] = useState([])
  const [empInfo, setEmpInfo] = useState([]);
  const { id } = useParams();

  const fetchData = async () => {
    await axios
      .get(`https://api.orbitmart.co.in/api/v1/order/${id}`)
      .then(async (res) => {
        console.log(res.data.data);
        setSucess(res.data.data);
        setSucessCommition(res);
        await axios
          .get(
            `https://api.orbitmart.co.in/api/v1/auth/byDistributor/${res.data.data?.employeeId}`
          )
          .then((res) => setEmpInfo(res.data.data));
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const orbitMartCommissionValue = sucess?.merchantId?.orbitMartCommission

  // console.log("success gst", sucess.productDetails[0]?.productId?.gst);
  // const subtotal = sucess?.productDetails
  //   ?.map((pro) => {
  //     return pro.productId?.itemPrice * pro?.quantity;
  //     // return pro?.productPrice * pro?.quantity;
  //   })
  //   .reduce((a, b) => a + b)||0;
  
  const subtotal = sucess?.productDetails
  ?.map((pro) => {
    return pro.productId?.itemPrice * pro?.quantity;
  })
  .reduce((a, b) => a + b) || 0;
  const orbitMartCommissionLogic = sucess?.productDetails
    ?.map((pr) => {
      return parseInt(pr?.productId?.orbitMartCommission);
    })
    console.log(orbitMartCommissionLogic, "sucess?.productDetails", sucess?.productDetails)

  const customerDiscount = sucess?.productDetails
    ?.map((pr) => {
      if (pr?.productId?.discountType == "FIXED") {
        return parseFloat(pr?.productId?.customerDiscount);
      } else {
        return (
          (parseFloat(pr?.productId?.itemPrice) *
            parseFloat(pr.quantity) *
            parseFloat(pr?.productId?.customerDiscount)) /
          100
        );
      }
    })
    .reduce((a, b) => a + b)||0;
  const WrongGst = sucess?.productDetails
    ?.map((pr) => {
      return parseInt(pr?.productId?.gst);
    })
    .reduce((a, b) => a + b);
  // const GstLogic =
  //   ((subtotal - customerDiscount) *
  //     parseFloat(sucess?.productDetails?.[0].productId?.gst)) /
  //   100||0;
  //   // const DeliveryAmount = 0;
    const DeliveryAmount=sucess?.totalDeliveryCharge;
    const GstLogic=sucess?.totalGST

    const custDiscount = sucess?.totalDiscount;

    const TotalAmount = (
      subtotal -
      custDiscount +
      GstLogic  
    //  - DeliveryAmount
    ).toFixed(0);

    

    // const TotalAmount = sucess?.totalPrice
  // const GstLogic=((sucess.totalPrice*parseFloat(sucess?.productDetails?.[0].productId?.gst))/100)

  //  const GstLogic=(parseInt(sucess.totalPrice)+parseInt(sucess?.productDetails[0]?.productId.gst))/100
  // const GstLogic =

  //   parseInt(
  //     (sucess.totalPrice - customerDiscountLogic + orbitMartCommissionLogic) *
  //       WrongGst
  //   ) / 100;
  // const GstLogic =
  // parseInt(
  //   (sucess.totalPrice - customerDiscountLogic + orbitMartCommissionLogic) *
  //     parseInt(sucess.productDetails[0]?.productId?.gst)
  // ) / 100;


  // const getAmount=(or)=>{
  //  return ((or.totalPrice)-(or.productDetails.map((pr)=>{

  //   if(pr?.productId?.discountType=='FIXED')
  //   {
  //     return  parseInt(pr.productId.customerDiscount)
  //   }
  //   else{
  //     return  (parseInt(pr.productId?.itemPrice)*parseInt(pr.productId?.customerDiscount!==""?pr.productId?.customerDiscount:0))/100
  //   }
  // }).reduce((a,b)=>a+b))+ (((or.totalPrice*parseInt(or.productDetails[0].productId?.gst))/100))+20).toFixed(0)
  // }

const commission=sucess?.productDetails
 ?.map((pr) => {
   return parseFloat(pr?.productId?.orbitMartCommission);
 })
 .reduce((a, b) => a + b)
 const payMerchant = (
   subtotal -
   subtotal * (orbitMartCommissionValue / 100) -
   customerDiscount +
   GstLogic
 ).toFixed(0);


 console.log({subtotal})
 console.log("commission", commission/ 100)
console.log({customerDiscount})
console.log({GstLogic})

console.log("total", subtotal -
  (subtotal * (commission / 100)) -
  customerDiscount +
  GstLogic)

console.log({payMerchant})

const gridConfirm = (props) => (
  <div class="flex space-x-2">
    <h6
      style={{ color: "#03c9d7", display: "flex" }}
      class="font-medium leading-tight mt-0 mb-2 "
    >
      {props.orderStatus == "DELIVERED" && <PaymentModal props={props} />}
    </h6>
  </div>
);

console.log(sucess.orderStatus)

 const pickDate = new Date(sucess?.distributorId?.createdAt);
 const delDate = new Date(sucess?.distributorId?.updatedAt);
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl ">
      <div class="fixbox ">
        <div class="box">
          <div class="flex justify-end active">
            <div class="backIcon">
              <Link to="/orders">
                <AiOutlineRollback />
              </Link>
            </div>
          </div> 
          <div class="flex-container  tablefont1">
            {sucess.userId?.firstName ?(<div>
            {sucess.userId?.firstName
            ?
             ( <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
                {sucess.userId?.firstName} {sucess.userId?.lastName}
              </h6>)
              : <span></span>
              }
            </div>): <span></span>
            }
          </div>
          <hr class="new1"></hr>
          <div className="flex-container flex-col md:flex-row tablefont1">
            <div className="mb-4 md:mb-0">
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 md:text-left">
                ORDER DATE
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 md:text-left">
                {new Date(sucess?.createdAt).toLocaleString("en-US", {
                  weekday: "short",
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </h6>
            </div>
            <div className="mb-4 md:mb-0">
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 md:text-left">
                ORDER NO.
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 md:text-left">
                {sucess?.orderNo}
              </h6>
            </div>
            <div className="mb-4 md:mb-0">
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 md:text-left">
                ORDER STATUS
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 md:text-left">
                {sucess?.orderStatus}
              </h6>
            </div>
          </div>
        </div>
        <div class="tablefont1 overflow-x-auto">
          <table class="min-w-full auto">
            <thead class="bg-white border-b">
              <tr>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900  text-left"
                >
                  MERCHANT
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  CATEGORY
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  CLASS
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  SUB CLASS
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  ITEMS
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  UNIT
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  QTY
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  AMOUNT
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-4 py-2 text-right "
                >
                  TOTAL
                </th>
              </tr>
            </thead>
            <tbody>
              {sucess?.productDetails?.map((pro) => (
                <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {sucess?.merchantData?.firmName}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {pro?.productId?.categoryId?.title}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {pro?.productId?.classificationId?.title}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {pro?.productId?.subClassificationId?.title}
                  </td>

                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {pro?.productId?.itemName}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {pro?.productId?.unitQuantityId?.title}
                  </td>

                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {/* {sucess.productDetails[0].productId.stockQuantity} */}

                    {pro?.quantity}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {/* {sucess.totalPrice} */}
                    {pro?.productId?.itemPrice}
                  </td>
                  <td class="px-6  py-4 whitespace-nowrap text-md font-medium text-green-900 text-right">
                    ₹{pro?.productId?.itemPrice * pro?.quantity || 0}
                  </td>
                </tr>
              ))}

              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  SUB TOTAL
                </td>
                <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-green-900 text-right">
                  ₹{subtotal}
                </td>
              </tr>
              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100 ">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  DISCOUNT AMOUNT
                  <span className=" py-4 whitespace-nowrap text-md font-small text-red-500 text-right">
                    (-)
                  </span>
                </td>
                <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-red-500 text-right">
                  ₹{custDiscount?.toFixed(2)}
                </td>
              </tr>

              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100 ">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  GST
                </td>
                {/* ViewPrice */}
                <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-green-900 text-right">
                  ₹{GstLogic?.toFixed(2)}
                </td>
              </tr>
              {/* <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  DELIVERY AMOUNT
                </td>
                <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-green-900 text-right">
                  ₹{DeliveryAmount}
                </td>
              </tr> */}

              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  FINAL AMOUNT
                  {/* <span className="finailAmoutnSign">(+)</span> */}
                  <span className=" py-4 whitespace-nowrap text-md font-small text-blue-500 text-right">
                    (+)
                  </span>
                </td>

                <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-green-900 text-right">
                  ₹{parseInt(TotalAmount).toFixed(0)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="footerfont">
          <div class="flex-container  tablefont1">
            {sucess?.orderStatus !== "PENDING" &&
            sucess?.orderStatus !== "PROCESSING" &&
            sucess?.orderStatus !== "REJECTED" ? (
              <div className="container_Assign ">
                <div className="Assign_hedding w-75">
                  <h3 className="Assign_hedding1">
                    Assign Employee Details
                    <span className="Assign_hedding2">*</span>
                  </h3>
                </div>
                <div className="AssignDtls">
                  <div>
                    <h5 className="DtlsName">
                      Order Pick Date and Time:-
                      <span className="asign1">
                        {pickDate.toLocaleString()}
                      </span>
                    </h5>
                  </div>
                  <div>
                    <h5 className="DtlsName">
                      {"Order Delivery Date and Time:-    "}
                      <span className="asign2">{delDate.toLocaleString()}</span>
                    </h5>
                  </div>
                  <div>
                    <h5 className="DtlsName">
                      Employee Name:-
                      <span className="asign4 ">{empInfo?.firstName}</span>
                    </h5>
                  </div>
                  <div>
                    <h5 className="DtlsName">
                      Employee Email:-
                      <span className="asign4">{empInfo?.email}</span>
                    </h5>
                  </div>
                  <div>
                    <h5 className="DtlsName">
                      Employee Mobile:-
                      <span className="asign5">{empInfo?.mobile}</span>
                    </h5>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div class="right">
              <h4 class="font-medium leading-tight text-2xl mt-0 mb-2">
                Total Amount
              </h4>
              <h3 class="font-large leading-tight text-3xl mt-0 mb-2 text-green-800">
                <span class="text-xxl inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded-full">
                  ₹{parseInt(TotalAmount).toFixed(0)}
                </span>
              </h3>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2">
                Taxes Included
              </h6>
              {sucess?.orderStatus !== "PENDING" 
              // sucess?.orderStatus !== "PROCESSING" &&
              // sucess?.orderStatus !== "REJECTED" 
              ? (
                <div className="tbody1">
                  Distributor will Pay you:<h6>₹{payMerchant}</h6>
                  <button>{gridConfirm(sucess)}</button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  );
};

export default ViewOrder;
