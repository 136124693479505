import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";

//Material Ui
import {
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import {
  FirstPageOutlined,
  LastPageOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";
import { Button, CircularProgress, Toolbar, Typography } from "@mui/material";

// import { voucherData, contextMenuItems, voucherGrid } from "../../assets/dummy";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { BsFillPencilFill } from "react-icons/bs";
import DeleteModel from "./DeleteModel";

const gridVoucherEdit = (itemId) => (
  <div style={{ display: "flex", justifyContent: "space-between" }}>
    <h1
      style={{ color: "#8be78b", display: "flex" }}
      className="font-small leading-tight text-xl mt-0 mb-2 "
    >
      <Link to="/voucher/edit">
        <BsFillPencilFill onClick={() => itemEdit(itemId)} />
      </Link>
    </h1>
    <h1
      style={{ color: "#03c9d7", display: "flex" }}
      className="font-small leading-tight text-xl mt-0 mb-2 "
    >
      <Link to={`/Voucher/view/${itemId._id}`}>
        <FiEye />
      </Link>
    </h1>
    <button
      style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
      className="font-small leading-tight text-xl mt-0 mb-2 "
    >
      <DeleteModel itemId={itemId} />
    </button>
  </div>
);

function mainPage() {
  navigate("/manageItem");
}

// const itemDelete = (itemId) => {
//   const deleteId = itemId._id;
//   console.log(deleteId, "chalakh");
//   fetch(`https://api.orbitmart.co.in/api/v1/voucher/${deleteId}`, {
//     method: "DELETE",
//   })
//     .then((res) => res.text()) // or res.json()
//     .then((res) => console.log(res));

//   mainPage();
// };

const itemEdit = (itemId) => {
  const editId = itemId._id;
  console.log(editId);
  setViewData(itemId);
};

const merchant = () => {
  const [tDatas, setTDatas] = useState([""]);
  const [totalPro, setTotalPro] = useState();
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);
    fetch("https://api.orbitmart.co.in/api/v1/voucher")
      .then((response) => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
        return response.json();
      })
      .then((data) => {
        setTDatas(data.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  console.log(totalPro);

  console.log(tDatas);

  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ["Search"];
  const editing = {};

  const navigate = useNavigate();
  const addPage = () => {
    navigate("/voucher/add");
  };

  const filteredData = tDatas?.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize
  );

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const CustomPaginationActions = (props) => {
    const { count, page, rowsPerPage, onChangePage } = props;
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };

    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };

    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPageOutlined />
        </Button>
        <Button onClick={handleBackButtonClick} disabled={page === 0}>
          <NavigateBefore />
        </Button>
        <span className="myPage">{currentPage + 1}</span>
        <Button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <NavigateNext />
        </Button>
        <Button
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <LastPageOutlined />
        </Button>
      </div>
    );
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const changeToFirstPage = () => {
    setLoading(true);
    setCurrentPage(0);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const changeToPrevPage = () => {
    setLoading(true);
    setCurrentPage(currentPage - 1);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const changeToNextPage = () => {
    setLoading(true);
    setCurrentPage(currentPage + 1);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const changeToLastPage = () => {
    setLoading(true);
    setCurrentPage(Math.ceil(tDatas?.length / pageSize) - 1);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <AppBar
        position="static"
        style={{ backgroundColor: "#003c7e", borderRadius: "20px" }}
      >
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography variant="h6">Vouchers ({tDatas?.length})</Typography>
          <span class="flex space-x-2 m-4 justify-end">
            <Button
              onClick={() => addPage()}
              variant="outlined"
              color="inherit"
            >
              Create Combo Voucher
            </Button>
          </span>
        </Toolbar>
      </AppBar>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Voucher Name</TableCell>
              <TableCell>Actual Amount</TableCell>
              <TableCell>Discount Amount</TableCell>
              <TableCell>Validity From</TableCell>
              <TableCell>Validity To</TableCell>
              <TableCell>Pincode</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.length > 0 ? (
              filteredData.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.ActualAmount}</TableCell>
                  <TableCell>{item.discountedMrp}</TableCell>
                  <TableCell>{item.validityFrom}</TableCell>
                  <TableCell>{item.validityUpto}</TableCell>
                  <TableCell>{item.pincode}</TableCell>
                  <TableCell>{gridVoucherEdit(item)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {tDatas.length > 0 ? (
          <TablePagination
            count={tDatas.length}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onChangePage={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10]}
            ActionsComponent={() => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  onClick={changeToFirstPage}
                  disabled={currentPage === 0}
                >
                  <FirstPageOutlined />
                </Button>
                <Button onClick={changeToPrevPage} disabled={currentPage === 0}>
                  <NavigateBefore />
                </Button>
                <span className="myPage">{currentPage + 1}</span>
                <Button
                  onClick={changeToNextPage}
                  disabled={
                    currentPage === Math.ceil(tDatas?.length / pageSize) - 1
                  }
                >
                  <NavigateNext />
                </Button>
                <Button
                  onClick={changeToLastPage}
                  disabled={
                    currentPage === Math.ceil(tDatas?.length / pageSize) - 1
                  }
                >
                  <LastPageOutlined />
                </Button>
              </div>
            )}
          />
        ) : null}
      </TableContainer>
    </div>
  );
};
export default merchant;
