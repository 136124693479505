import React, { useState, useContext, useEffect } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
// import { merchantData, contextMenuItems, merchantGrid } from "../../assets/dummy";
import { Header } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import { BiSearch } from "react-icons/bi";
import DeleteModel from "./DeleteModel";
import {
  merchantGrid,
  merchantData,
  contextMenuItems,
} from "./config/OffersConfig";
import { BsFillPencilFill } from "react-icons/bs";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import StateContext from "../../contexts/ContextProvider";
import axios from "axios";

//Material Ui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  IconButton,
  Icon,
  AppBar,
} from "@material-ui/core";
import {
  FirstPage,
  FirstPageOutlined,
  LastPage,
  LastPageOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";
import { Button, CircularProgress, Toolbar, Typography } from "@mui/material";
import { borderRadius } from "@mui/system";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { addOffer } from "../../store/offerSlice";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse", 
    border: 'none'
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "15px", // Add padding for cell content
    textAlign: "start", // Adjust text alignment if needed
  },
  cell: {
    // border: "1px solid " + theme.palette.divider, // Border for all cells
    padding: theme.spacing(1), // Adjust padding as needed
    textAlign: "start", // Adjust text alignment as needed
    whiteSpace: "pre-line", // Preserve line breaks
    wordBreak: "break-word", // Break word at the end of line if needed
    paddingLeft: '16px'
  },
  celltable: {
    marginTop: "10px"
  }
}));

const Offers = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [tDatas, setTDatas] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const random = 153;

  const { merId } = useContext(StateContext);
  const { value } = useContext(StateContext);
  const { setValue } = useContext(StateContext);
  const [loading, setLoading] = useState(false);
  // viewData,
  // setViewData,

  const { viewData,setViewData } = useContext(StateContext);

  useEffect(() => {
    if (merId) {
      fetchData();
      setValue(201)
    }
  }, [value, merId]);

  const addPage = () => {
    navigate("/offers/add");
  };

  function fetchData() {
    const id = merId;
    setLoading(true);
    axios
      .get(`https://api.orbitmart.co.in/api/v1/offer/user/${id}`)
      .then((res) => {
        console.log(res);
        const datas = res.data;
        console.log({ datas }, "scdsjkcsdc");
        setTDatas(datas.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  }

  var itemId = tDatas.data;

  // function gridActions(itemId) {
  //   return (
  //     <div style={{ display: "flex", justifyContent: "space-evenly" }}>
  //       <h1
  //         style={{ color: "#8be78b", display: "flex" }}
  //         className="font-small leading-tight text-xl mt-0 mb-2 "
  //       >
  //         {/* <Link to={`/offers/edit/${itemId._id}`}>
  //           <BsFillPencilFill onClick={() => itemEdit(itemId)} />
  //         </Link> */}

  //           <BsFillPencilFill onClick={() => itemEdit(itemId)} />
  //       </h1>
  //       <button
  //         style={{ color: "#ff5c8e", display: "flex" }}
  //         className="font-small leading-tight text-xl mt-0 mb-2 "
  //       >
  //         <DeleteModel itemId={itemId._id} />
  //       </button>
  //     </div>
  //   );
  // }

  function gridActions(itemId) {
    return (
      <div className="flex items-center gap-2">
        <h1
          style={{ color: "#8be78b", display: "flex" }}
          className="font-small leading-tight text-xl mt-0 mb-2 "
        >
          {/* <Link to={`/offers/edit/${itemId._id}`}>
            <BsFillPencilFill onClick={() => itemEdit(itemId)} />
          </Link> */}
 
          <BsFillPencilFill onClick={() => itemEdit(itemId)} />
        </h1>
        <button
          style={{ color: "#ff5c8e", display: "flex"}}
          className="font-small leading-tight text-xl mt-0 mb-2 "
        >
          <DeleteModel itemId={itemId._id} />
        </button>
      </div>
    );
  }

  const itemEdit = (itemId) => {
    // setViewData(itemId)
    dispatch(addOffer(itemId));
    if(itemId.productId.length > 0) {
    navigate(`/offers/edit`)
  }
  console.log(itemId, "hiiii",viewData)
  };

  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ["Search"];
  const editing = {};

  const handleChangePage = (event, newPage) => {
    if (newPage === 0) {
      setCurrentPage(0);
    } else if (newPage === Math.ceil(tDatas?.length / pageSize) - 1) {
      setCurrentPage(Math.ceil(tDatas?.length / pageSize) - 1);
    } else {
      setCurrentPage(newPage);
    }
  };

  const filtered_Data = tDatas?.filter((item) => {
    return item.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const currentPageData = filtered_Data?.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize
  );

  const handleChangePageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const CustomPaginationActions = (props) => {
    const { count, page, rowsPerPage, onChangePage } = props;
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };

    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    const handleNextButtonClick = (event) => {
      setLoading(true);
      onChangePage(event, page + 1);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPageOutlined />
        </Button>
        <Button onClick={handleBackButtonClick} disabled={page === 0}>
          <NavigateBefore />
        </Button>
        <span className="myPage">{currentPage + 1}</span>
        <Button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <NavigateNext />
        </Button>
        <Button
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <LastPageOutlined />
        </Button>
      </div>
    );
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const changeToFirstPage = () => {
    setLoading(true);
    setCurrentPage(0);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const changeToPrevPage = () => {
    setLoading(true);
    setCurrentPage(currentPage - 1);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const changeToNextPage = () => {
    setLoading(true);
    setCurrentPage(currentPage + 1);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const changeToLastPage = () => {
    setLoading(true);
    setCurrentPage(Math.ceil(tDatas?.length / pageSize) - 1);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  console.log("current page", currentPage);
  console.log("page size", pageSize);
  console.log("total data", tDatas?.length);
  console.log(Math.ceil(tDatas?.length / pageSize));

  return (
    // m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl
    <div className="mx-2 my-0 md:m-10 mt-24 px-2 pt-1 md:p-10 bg-white rounded-3xl">
      <span class="flex space-x-2 m-4 justify-end">
        <Button
          className="newRecordBtn"
          onClick={() => addPage()}
          variant="outlined"
          color="inherit"
        >
          New Record
        </Button>
      </span>
      <AppBar
        position="static"
        style={{ backgroundColor: "#003c7e", borderRadius: "20px" }}
      >
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography variant="h6" className="hidden sm:block">Offers ({tDatas?.length})</Typography>
          <span class="flex space-x-2 m-4 justify-end" style={{ background: 'white', borderRadius: '6px' }}>
            <input
              style={{ padding: "4px 10px", outline: 'none', borderRadius: '6px 0 0 6px', color: 'black' }}
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <BiSearch style={{ fontSize: '25px', color: 'gray', cursor: 'pointer', margin: 'auto', paddingRight: '5px' }} />   {/*  this is the search icon */}
          </span>
        </Toolbar>
      </AppBar>
      <TableContainer className={currentPageData?.length > 0 ? "table-container" : ""}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellRow}>Name</TableCell>
              <TableCell className={classes.cellRow}>Offer Type</TableCell>
              <TableCell className={classes.cellRow}>Description</TableCell>
              <TableCell className={classes.cellRow}>Discount Percentage</TableCell>
              <TableCell className={classes.cellRow}>Total Products</TableCell>
              <TableCell className={classes.cellRow}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {currentPageData?.length > 0 ? (
              currentPageData.map((item) => (
                <TableRow key={item._id}>
                  <TableCell className={classes.cell}>{item.name}</TableCell>
                  <TableCell className={classes.cell}>{item.offerType}</TableCell>
                  <TableCell className={classes.cell}>{item.description}</TableCell>
                  <TableCell className={classes.cell}>{item.offerDiscount}</TableCell>
                  <TableCell className={classes.cell}>{item.productId.length}</TableCell>
                  <TableCell className={classes.cell}>{gridActions(item)}</TableCell>
                  {console.log(item.productId)}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {filtered_Data?.length > 0 ? (
          <TablePagination
            count={filtered_Data?.length}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onChangePage={handleChangePage}
            onRowsPerPageChange={handleChangePageChange}
            rowsPerPageOptions={[10]}
            showFirstButton={true}
            showLastButton={true}
            ActionsComponent={CustomPaginationActions}
          />
        ) : null}
      </TableContainer>
    </div>
  );
};
export default Offers;
