import React, { useContext, useState } from "react";
import { BsCurrencyDollar } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import { IoIosMore } from "react-icons/io";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Stacked, Pie, Button, LineChart, SparkLine } from "../components";
import {
  earningData,
  medicalproBranding,
  recentTransactions,
  weeklyStats,
  dropdownData,
  SparklineAreaData,
  ecomPieChartData,
} from "../assets/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import product9 from "../assets/product9.jpg";
import StateContext from "../contexts/ContextProvider";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CChart } from "@coreui/react-chartjs";
import { CChartBar } from "@coreui/react-chartjs";
import { Link } from "react-router-dom";

const DropDown = ({ currentMode }) => (
  <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
    <DropDownListComponent
      id="time"
      fields={{ text: "Time", value: "Id" }}
      style={{ border: "none", color: currentMode === "Dark" && "white" }}
      value="1"
      dataSource={dropdownData}
      popupHeight="220px"
      popupWidth="120px"
    />
  </div>
);

const Merchant = () => {
  const { currentColor, currentMode } = useStateContext();
  const [totalOrder, setTotalOrder] = useState("");
  const [pending, setPending] = useState("");
  const [processing, setProcessing] = useState("");
  const [packing, setPacking] = useState("");
  const [shipping, setShipping] = useState("");
  const [delivered, setdelivered] = useState("");
  const [completed, setCompleted] = useState("");
  const [cancelled, setCancelled] = useState("");
  const [rejected, setRejected] = useState("");
  const [chartdata, setChartData] = useState([]);
  const { token } = useContext(StateContext);
  const { setMerchantId } = useContext(StateContext);
  const { setMerId } = useContext(StateContext);
  const { setPincod, setProfileData } = useContext(StateContext);
  const { setOrbitMartCommission } = useContext(StateContext);
  const CHART_DATA = chartdata;

  const navigate = useNavigate();

  const [data, setData] = useState([""]);

  function logout() {
    navigate("/");
    window.sessionStorage.clear();
    window.location.reload();
  }
  const [selectedMonth, setSelectedMonth] = useState("");

  const handleChange = (e) => {
    setSelectedMonth(e.target.value);
  };
  const dateString = selectedMonth;
  const date = new Date(dateString);
  let monthName = date
    .toLocaleString("default", { month: "long" })
    .toLowerCase();

  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  if (months.includes(monthName)) {
    monthName = "month";
  }

  const chart_Function = async () => {
    try {
      let filterDate = "";
      let filter = "";

      if (filter === "invalid date") {
        filterDate = "2023-07";
        filter = "month";
      } else {
        filterDate = selectedMonth;
        filter = monthName;
      }
      const graphDta = {
        // filterDate: selectedMonth,
        // filter: monthName
        filter: "Monthly",
        // filterDate: "2023-07"
      };
      const headers = {
        "x-token": token,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        "https://api.orbitmart.co.in/api/v1/order/graph",
        graphDta,
        {
          headers,
        }
      );
      setChartData(response.data.data.month)

    } catch (e) {
      console.log(e);
    }
  }

  function fetchProfile() {
    // debugger;
    const headers = {
      "x-token": token,
    };
    const getProfileAPI = "https://api.orbitmart.co.in/api/v1/auth/get-profile";
    axios
      .get(getProfileAPI, { headers })
      .then((res) => {
        console.log(res.data);
        console.log(res.data.data);
        setData(res.data.data);
        setProfileData(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
        logout();
      });
  }

  // function fetchOrders() {
  //   const getOrders =
  //     "https://api.orbitmart.co.in/api/v1/order/user/637c6e797ff9630456bb94db";
  //   axios
  //     .get(getOrders)
  //     .then((res) => setTotalOrder(res.data.data))
  //     .catch((err) => console.log(err));
  // }

  const merchantTypeId = data.merchantTypeId;
  const merId = data._id;

  function fetchOrders() {
    const getOrders = `https://api.orbitmart.co.in/api/v1/order/user/${merId}`;
    axios
      .get(getOrders)
      .then((res) => {
        const totalOrders = res.data.data;
        let pendings = [];
        let processings = [];
        let packings = [];
        let shippings = [];
        let delivers = [];
        let completes = [];
        let cancels = [];
        let rejects = [];
        totalOrders.forEach((ele) => {
          switch (ele.orderStatus) {
            case "PENDING":
              pendings.push(ele);
              break;
            case "PROCESSING":
              processings.push(ele);
              break;
            case "PACKAGING":
              packings.push(ele);
              break;
            case "SHIPPING":
              shippings.push(ele);
              break;
            case "DELIVERED":
              delivers.push(ele);
              break;
            case "COMPLETED":
              completes.push(ele);
              break;
            case "CANCELLED":
              cancels.push(ele);
              break;
            case "REJECTED":
              rejects.push(ele);
              break;
          }
        });
        // debugger;
        setTotalOrder(totalOrders.length);
        setPending(pendings);
        setProcessing(processings);
        setPacking(packings);
        setShipping(shippings);
        setdelivered(delivers);
        setCompleted(completes);
        setCancelled(cancels);
        setRejected(rejects);
      })
      .catch((err) => console.log(err));
  }

  setMerchantId(merchantTypeId);
  setMerId(merId);

  const orbitMart = data.orbitMartCommission;
  setOrbitMartCommission(orbitMart);

  const pinCo = data.pincode;
  setPincod(pinCo);

  useEffect(() => {
    fetchProfile();
    fetchChartData();
    if (merId) {
      fetchOrders();
    }
  }, [merId]);

  const fetchChartData = async () => {
    try {
      const getOrders = "https://api.orbitmart.co.in/api/v1/order/bar";
      const result = await axios.post(getOrders);
      setChartData(result.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(()=> chart_Function(),[])
  return (
    // <div style={{marginTop: '2rem'}}>{/*className="mt-24"*/}
    <div className="mt-24">
      {/*className="mt-24"*/}
      <div className="d-flex flex-wrap lg:flex-nowrap justify-left ">
        <div
          style={{
            display: "flex",
            margin: "20px",
            // justifyContent: "space-evenly",
            justifyContent: "space-around",
          }}
        >
          <div>
            <div>
              <Link to="/orders">
                <div
                  style={{
                    boxShadow:
                      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                    width: "350px",
                  }}
                  className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
                >
                  <div className="flex justify-between items-center">
                    <span className="font-semibold text-gray-400">TOTAL </span>
                    <span>{totalOrder ? totalOrder : 0}</span>
                  </div>
                </div>
              </Link>
            </div>

            <div>
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">Pending </span>
                  <span>{pending ? pending.length : 0}</span>
                </div>
              </div>
            </div>
            <div>
              {/* <Link to="/orders"> */}
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    Processing{" "}
                  </span>
                  <span>{processing ? processing.length : 0}</span>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div>
              {/* <Link to="/orders"> */}
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">Packing </span>
                  <span>{packing ? packing.length : 0}</span>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div>
              {/* <Link to="/orders"> */}
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">Shipping </span>
                  <span>{shipping ? shipping.length : 0}</span>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div>
              {/* <Link to="/orders"> */}
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    Delivered{" "}
                  </span>
                  <span>{delivered ? delivered.length : 0}</span>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div>
              {/* <Link to="/orders"> */}
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    Completed{" "}
                  </span>
                  <span>{completed ? completed.length : 0}</span>
                </div>
              </div>
              {/* </Link> */}
            </div>
            <div>
              {/* <Link to="/orders"> */}
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    Cancelled/Rejected{" "}
                  </span>
                  <span>
                    {cancelled ? cancelled.length : 0}/
                    {rejected ? rejected.length : 0}
                  </span>
                </div>
              </div>
              {/* </Link> */}
            </div>
          </div>

          <div>
            <div style={{ display: "flex" }}>
              <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-94 rounded-xl w-full lg:w-80 p-4 pt-5 m-1   bg-no-repeat bg-cover bg-center">
                <div>
                  {/* <h2 style={{ fontSize: "18px" }}>Product</h2> */}
                  <h2>Product</h2>
                </div>
                <CChart
                  type="doughnut"
                  data={{
                    labels: [
                      "Total order delivered",
                      "Pending orders        ",
                      "Order completed     ",
                      "Order cancelled       ",
                    ],
                    datasets: [
                      {
                        backgroundColor: [
                          "#41B883",
                          "#E46651",
                          "#00D8FF",
                          "#b84176",
                        ],
                        data: [
                          delivered.length,
                          pending.length,
                          completed.length,
                          rejected.length +
                          cancelled.length,
                        ],
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-90 rounded-xl lg:w-100 p-8 pt-9 m-3  bg-no-repeat bg-cover bg-center"> */}
          <div className="bg-white dark:text-black-200 dark:bg-secondary-dark-bg h-90 rounded-xl w-full lg:w-100 p-8 pt-9 m-3  bg-no-repeat bg-cover bg-center">
            <div style={{ display: "flex" }}>
              {/* <div class="relative z-0 p-2 w-full mb-6 group">
                <label>Order Type</label>
                <select
                  class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repea
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-label="Default select example"
                >
                  <option>All</option>
                  <option value="MALE">Oriz</option>
                  <option value="FEMALE">counter sell</option>
                  <option value="FEMALE">Personal app</option>
                </select>
              </div> */}
{/* 
              <div class="relative z-0 p-2 w-full mb-6 group">
                <label>Order Status</label>
                <select
                  class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repea
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-label="Default select example"
                >
                  <option>All</option>
                  <option value="Cancel">Cancel</option>
                  <option value="Pending">Pending</option>
                  <option value="Processing">Processing</option>
                </select>
              </div> */}

              <div class="relative z-0 p-2 w-full mb-6 group">
                <label>Payment Status </label>
                <select
                  class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repea
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-label="Default select example"
                >
                  <option>All</option>
                  <option value="Pending">Pending</option>
                  <option value="Complete">Complete</option>
                </select>
              </div>

              <div class="relative z-0 p-2 w-full mb-6 group">
                <label>Month/Year</label>
                <input
                  type="month"
                  class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Enter Month/Year"
                  value={selectedMonth}
                  onChange={handleChange}
                />
              </div>
            </div>
            <CChartBar
              data={{
                labels: [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ],
                datasets: [
                  {
                    label: "Restaurant Order Statistics",
                    backgroundColor: "#f87979",
                    data: [
                      chartdata?.January,
                      chartdata?.February,
                      chartdata?.March,
                      chartdata?.April,
                      chartdata?.May,
                      chartdata?.June,
                      chartdata?.July,
                      chartdata?.August,
                      chartdata?.September,
                      chartdata?.October,
                      chartdata?.November,
                      chartdata?.December,
                    ],
                  },
                ],
              }}
              labels="months"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Merchant;