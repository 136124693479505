import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import { createContext, useContext, useState, useEffect } from "react";
import "../../pages/view.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactDOM from "react-dom";
import axios from "axios";
import ConfirmPopup from "./ConfirmPopup";
import RejectPopup from "./RejectPopup";
import { showErrorMsg } from "../../components/helper/message";
import "./confirmModel.css";

//icon
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import StateContext from "../../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

const rows = [
  createData(0, 0, "Pizza GardenDelight", 4, 500),
  createData(1, 1, "Veg Cheese Burger", 2, 80),
];

function priceRow(qty, unit) {
  return qty * unit;
}

function createData(id, srno, name, qty, unit) {
  const price = priceRow(qty, unit);
  return { id, srno, name, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

let invoiceSubtotal = subtotal(rows);

const handleChange = (e) => {
  createData(e.target.value);
  e.preventDefault();
};

const reducer = (state, action) => {
  switch (action.type) {
    case "increment":
      return state.map((substate) => {
        if (action.id.id === substate.id) {
          invoiceSubtotal = subtotal(state) + substate.unit;
          return {
            ...substate,
            qty: substate.qty + 1,
            price: (substate.qty + 1) * substate.unit,
          };
        } else {
          return substate;
        }
      });
    case "decrement":
      return state.map((substate) => {
        if (action.id.id === substate.id && substate.qty > 0) {
          invoiceSubtotal = subtotal(state) - substate.unit;
          return {
            ...substate,
            qty: substate.qty - 1,
            price: (substate.qty - 1) * substate.unit,
          };
        } else {
          return substate;
        }
      });
    default:
      return state;
  }
};

export default function ConfirmModal(props) {
  // const [quant, dispatch] = React.useReducer(reducer, rows);
  const [open, setOpen] = React.useState(false);
  const [gstValue, setGstValue] = useState(props.props.totalGST || 0);
  const [quantity, setQuantity] = useState(1);

  const { merId } = useContext(StateContext);

  const [data, setData] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [product, setProduct] = useState(props.props.productDetails);

  const subtotal =
    props.props?.productDetails
      ?.map((pro) => {
        return pro.productId?.itemPrice * pro?.quantity;
      })
      .reduce((a, b) => a + b) || 0;
  const orbitMartCommissionLogic =
    props.props?.productDetails
      ?.map((pr) => {
        return parseInt(pr?.productId?.orbitMartCommission);
      })
      .reduce((a, b) => a + b) || 0;

  const customerDiscount =
    props.props?.productDetails
      ?.map((pr) => {
        if (pr?.productId?.discountType == "FIXED") {
          return parseFloat(pr?.productId?.customerDiscount);
        } else {
          return (
            (parseFloat(pr?.productId?.itemPrice) *
              parseFloat(pr.quantity) *
              parseFloat(pr?.productId?.customerDiscount)) /
            100
          );
        }
      })
      .reduce((a, b) => a + b) || 0;

  const GstLogic =
    ((subtotal - customerDiscount) *
      parseFloat(props.props?.productDetails?.[0].productId?.gst)) /
      100 || 0;

  const DeliveryAmount = props.props.totalDeliveryCharge;
  const TotalAmount = (subtotal - customerDiscount + gstValue).toFixed(0);

  useEffect(() => {
    fetchOrders();
    // totalGST();
  }, [product, props.props]);

  function fetchOrders() {
    axios
      .get(`https://api.orbitmart.co.in/api/v1/order/user/${merId}`)
      .then((res) => {
        console.log(res.data);
        setData(res.data.data);
      })
      .catch((err) => {});
  }

   console.log({product})
  function totalGST() {
    let TotalGST = 0;
    console.log(props.props);
    for (let i = 0; i < product.length; i++) {
      if (product[i].productId.gst != 0) {
        console.log(`${i}`, product[i]);
        if (product[i].productId.discountType === "PERCENT") {
          let totalProductPrice =
            product[i].productId.itemPrice * product[i].quantity;
          let discountPrice =
            totalProductPrice * (product[i].productId.customerDiscount / 100);
          let discountedPrice = totalProductPrice - discountPrice;
          let finalPrice = discountedPrice * (product[i].productId.gst / 100);
          TotalGST = TotalGST + finalPrice;
        } else if (product[i].productId.discountType === "FIXED") {
          let totalProductPrice =
            product[i].productId.itemPrice * product[i].quantity;
          let discountPrice =
            totalProductPrice - product[i].productId.customerDiscount;
          let discountedPrice = totalProductPrice - discountPrice;
          if (discountedPrice != 0) {
            let finalPrice = discountedPrice * (product[i].productId.gst / 100);
            TotalGST = TotalGST + finalPrice;
          } else {
            let finalPrice = discountPrice * (product[i].productId.gst / 100);
            TotalGST = TotalGST + finalPrice;
          }
        }
      }
    }
    setGstValue(TotalGST);
  }

  const [errMsg, setErrMsg] = useState(false);
  const [msg, setMsg] = useState("");
  var timeout;
  const displayErrMsg = (setErrMsg, timeout) => {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    }, 2000);
    return timeout;
  };
  const [confirmopen, setConfirmOpen] = React.useState(false);
  const [rejectOpen, setRejectOpen] = React.useState(false);
  const [date, setDate] = React.useState();

  const [ordernum, setOrdernum] = React.useState(
    props.props.orderNo.toUpperCase()
  );
  const HandleReject = () => setRejectOpen(true);
  const API = "https://api.orbitmart.co.in/api/v1/order/";
  const { setValue } = useContext(StateContext);

  console.log({gstValue})

  const navigate = useNavigate()

  const HandleConfirm = async () => {
    if (dateresult === "NaN/NaN/NaN") {
      setMsg(`Please select pickup date and time`);
      timeout = displayErrMsg(setErrMsg, timeout);
      setLoading(false);
    } else {
      const payload = {
        orderStatus: "PROCESSING",
        date: dateresult,
        time: timeresult,
        totalPrice: TotalAmount,
        productDetails: product,
        totalGST: gstValue,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-token": JSON.parse(sessionStorage.getItem("merchant_login_data"))
            .data.token,
        },
      };
      await axios
        .put(`${API}/${props.props._id}`, payload, config)
        .then((response) => {
          const SuccessRes = response.data.status == "SUCCESS";
          if (SuccessRes) {
            setConfirmOpen(true);
            // setValue(200);
            setValue(response.data.code);
            setOpen(false);
          }
          console.log("confirm response", response);
        })
        .catch((error) => {
          console.log("confirm response error!", error);
        });
    }
    navigate("/orders");
  };

  // =================date time start===============
  const handleDate = (e) => {
    setDate(e);
  };
  const d1 = new Date(date);
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  }
  function formatTime(date) {
    var d = new Date(date),
      hour = "" + d.getUTCHours(),
      minute = "" + d.getUTCMinutes(),
      second = d.getUTCSeconds();

    if (minute.length < 2) minute = "0" + minute;
    if (second.length < 2) second = "0" + second;

    return [hour, minute, second].join(":");
  }

  var dateresult = formatDate(d1);
  var timeresult = formatTime(d1);

  function handleQtyChange(e, id, action) {
    console.log("id", id, "action", action);
    console.log("product", product);
    let tempProd = product;
    tempProd.map((pro) => {
      console.log("pro", pro);
      if (pro._id === id) {
        if (action === "+") {
          setQuantity(pro.quantity + 1);
          pro.quantity += 1;
        } else {
          setQuantity(pro.quantity - 1);
          pro.quantity -= 1;
        }
      } else {
        console.log(pro._id);
      }
    });
    setProduct(tempProd);
    totalGST();
    console.log("products", product);
    console.log("add");
  }

  return (
    <div>
      {errMsg && showErrorMsg(msg, errMsg)}
      <button
        style={{ color: "white", backgroundColor: "#8be78b" }}
        type="button"
        onClick={() => handleOpen()}
        class="px-6
      py-2.5
      bg-blue-600
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out"
      >
        Confirm
      </button>
      <ConfirmPopup open={confirmopen} setConfirmOpen={setConfirmOpen} />
      <RejectPopup
        open={rejectOpen}
        setRejectOpen={setRejectOpen}
        data={props}
        parentmodal={setOpen}
      />
      <Modal
        open={open}
        onChange={() => handleChange(e)}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        testId={createData}
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div class="flex justify-end active">
            <div class="backIcon">
              <button onClick={handleClose}>
                <AiOutlineRollback />
              </button>
            </div>
          </div>
          <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
            Order Confirmation
          </h4>
          <form>
            <div className="grid xl:grid-cols-2  m:gap-2">
              <div class="relative z-0 p-2 w-full mb-6 group">
                <label
                  for="orderno"
                  class="form-label inline-block mb-2 text-gray-700"
                >
                  Order No
                </label>
                <input
                  type="text"
                  class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="orderno"
                  placeholder="F02222200000"
                  readonly
                  value={ordernum + 1}
                />
                <p className="invisible peer-invalid:visible text-red-700 font-light">
                  Please Enter Your Valid order no
                </p>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                <label
                  for="date"
                  class="form-label inline-block mb-2 text-gray-700"
                >
                  Pick Up Date & Time
                </label>
                <input
                  type="datetime-local"
                  class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="date"
                  placeholder="Select Data & Time"
                  onChange={(e) => handleDate(e.target.value)}

                  // value={date}
                />
              </div>
              <p className="invisible peer-invalid:visible text-red-700 font-light">
                Please Enter Your Valid order no
              </p>
            </div>
          </form>

          <TableContainer onChange={() => handleChange()} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. no</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="left">Unit Price</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {product.map((pr, index) => (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">
                      {pr?.productId?.itemName}
                    </TableCell>
                    <TableCell align="center">
                      <button
                        disabled={pr.quantity === 1}
                        style={{
                          width: "35px",
                          background: "#B32624",
                          color: "white",
                          fontSize: "17px",
                          borderRadius: '3px',
                          cursor: "pointer",
                        }}
                        onClick={(e) => handleQtyChange(e, pr._id, "-")}
                      >
                        -
                      </button>{" "}
                      {pr?.quantity}{" "}
                      <button
                        style={{
                          width: "35px",
                          background: "#24B1B3",
                          color: "white",
                          fontSize: "17px",
                          borderRadius: '3px',
                          cursor: "pointer",
                        }}
                        onClick={(e) => handleQtyChange(e, pr._id, "+")}
                      >
                        +
                      </button>
                    </TableCell>
                    <TableCell align="left">
                      {pr?.productId?.itemPrice}
                    </TableCell>
                    <TableCell align="right">
                      ₹
                      {parseInt(pr?.productId?.itemPrice) *
                        parseInt(pr?.quantity)}
                    </TableCell>{" "}
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell style={{ fontWeight: "bold" }} align="left">
                    Subtotal
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", color: "#03c9d7" }}
                    align="right"
                  >
                    ₹{subtotal}
                    {/* {invoiceSubtotal} */}
                    {/* {pr.totalPrice} */}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell style={{ fontWeight: "bold" }} align="left">
                    Discount
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", color: "red" }}
                    align="right"
                  >
                    ₹{Math.round(customerDiscount.toFixed(2))}
                    {/* {invoiceSubtotal} */}
                    {/* {pr.totalPrice} */}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell style={{ fontWeight: "bold" }} align="left">
                    Gst
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="right">
                    ₹{Math.round(gstValue.toFixed(2))}
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell style={{ fontWeight: "bolder" }} align="left">
                    Delivery Charges
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="right">
                    ₹{DeliveryAmount}
                  </TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell style={{ fontWeight: "bold" }} align="left">
                    Grand Total
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", color: "#03c9d7" }}
                    align="right"
                  >
                    {/* {orizCom+20-discount+gst+subtotal} */}₹{TotalAmount}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ paddingTop: 15 }}></div>
          <div className="flex space-x-2 justify-end">
            <button
              type="button"
              className="addButton conf_Btn"
              style={{ width: "20%", border: 'none' }}
              onClick={HandleConfirm}
            >
              Confirm order
            </button>
            <button
              type="button"
              className="backButton can_Btn"
              style={{ width: "20%", border: 'none' }}
              onClick={HandleReject}
            >
              Cancel order
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
