import React, { useState, useEffect, useContext } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { Button } from ".";
import { userProfileData } from "../assets/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import avatar from "../assets/avatar.jpg";
import StateContext from "../contexts/ContextProvider";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const UserProfile = () => {
  const { token } = useContext(StateContext);
  const { currentColor, setIsClicked, initialState } = useStateContext();
  const { setAuth } = useContext(StateContext);
  const { setPincod } = useContext(StateContext);
  const { setProfileData } = useContext(StateContext);

  const navigate = useNavigate();

  const [data, setData] = useState([""]);
  const logoutColor = "#ff5c8e";

  function fetchProfile() {
    const headers = {
      "x-token": token,
    };
    const getProfileAPI = "https://api.orbitmart.co.in/api/v1/auth/get-profile";
    axios
      .get(getProfileAPI, { headers })
      .then((res) => {
        console.log(res.data);
        console.log(res.data.data);
        setData(res.data.data);
        setProfileData(res.data.data);
        setPincod(res.data.data.pincode);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  useEffect(() => {
    fetchProfile();
  }, []);

  console.log(data);

  function logout() {
    navigate("/");
    localStorage.removeItem("Login_Data");
    window.sessionStorage.clear();
    window.location.reload();
  }

  function editProfile() {
    navigate("/editprofile");
  }

  function handleClose() {
    <Button icon={<MdOutlineCancel />} />;
  }

  return (
    <ClickAwayListener
      onClickAway={() => setIsClicked({ ...initialState, userProfile: false })}
    >
      <div className="nav-item absolute right-1 top-1 bg-white dark:bg-[#42464D] p-8 rounded-lg w-46">
        <div className="flex justify-between items-center">
          <p className="font-semibold text-lg dark:text-gray-200">
            {data.firstName}
          </p>
          {/* <button
            style={{
              borderRadius: "50%",
              fontSize: "20px",
              color: "#ff5c8e",
              bgHoverColor: "light-gray",
            }}
          >
            <MdOutlineCancel onClick={handleClose} />
          </button> */}
          <Button
            icon={<MdOutlineCancel />}
            color="rgb(153, 171, 180)"
            bgHoverColor="light-gray"
            size="2xl"
            borderRadius="50%"
          />
        </div>
        <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
          {/* <div style={{width: '24px', height: '24px', border: '1px solid red'}}> */}
            <img
            style={{ width: 100 ,
              height: 100,
              borderRadius: 100 / 2,
              overflow: "hidden",
              borderWidth: 2,
              borderColor: "rgb(158, 235, 71)", objectFit:'cover'}}

              className="rounded-full h-24 w-24"
              src={data.avatar}
              alt="user-profile"
              // sx={{width: 24, height: 24}}
              // style={{ width: "100%", height: "100%" }}
            />
          {/* </div> */}
          <div>
            <p className="text-gray-500 text-sm dark:text-gray-400">
              {data.role}
            </p>
            <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
              Name - {data.firstName}
            </p>
            <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
              Email - {data.email}
            </p>
            <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
              Pincode - {data.pincode}
            </p>
          </div>
        </div>
        <div className="m-2">
          <button
            onClick={editProfile}
            style={{
              width: "50%",
              marginLeft: 20,
              backgroundColor: "#8be78b",
              height: 30,
              color: "white",
              borderRadius: 8,
              alignItems: "center",
              alignContent: "center",
            }}
          >
            Edit Profile
          </button>
          <button
            onClick={logout}
            style={{
              width: "35%",
              marginLeft: 10,
              backgroundColor: "#ff5c8e",
              height: 30,
              color: "white",
              borderRadius: 8,
              alignItems: "center",
              alignContent: "center",
            }}
          >
            Logout
          </button>
        </div>

        {/* <div>
        {userProfileData.map((item, index) => (
          <div
            key={index}
            className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
          >
            <button
              type="button"
              style={{ color: item.iconColor, backgroundColor: item.iconBg }}
              className=" text-xl rounded-lg p-3 hover:bg-light-gray"
            >
              {item.icon}
            </button>

            <div>
              <p className="font-semibold dark:text-gray-200 ">{item.title}</p>
              <p className="text-gray-500 text-sm dark:text-gray-400">
                {" "}
                {item.desc}{" "}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5"></div> */}
        {/* <Button
        color="white"
        bgColor={logoutColor}
        text="Logout"
        borderRadius="10px"
        width="full"
        onClick={() => (window.localStorage.clear(), window.location.reload())}
      /> */}
      </div>
    </ClickAwayListener>
  );
};

export default UserProfile;
