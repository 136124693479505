import React, { createContext, useContext, useState, useReducer } from "react";
import authInitialState from "./initialStates/authInitialState";
import auth from "./reducers/auth";

const StateContext = createContext({});

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
  //LoginContext
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null,
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
 const[orderInfo,setOrderInfo]=useState([])
  const [auth, setAuth] = useState(false);
  const [banner, setBanner] = useState([]);

  //Token
  const [token, setToken] = useState("");
  //MerchantTypeId
  const [merchantId, setMerchantId] = useState("");
  //
  const [merId, setMerId] = useState("");

  const [viewData, setViewData] = useState([""]);

  const [value, setValue] = useState("");
  const [orbitMartCommission, setOrbitMartCommission] = useState("");

  const [loginData, setLoginData] = useState([""]);

  const [profileData, setProfileData] = useState([]);
  const [pincod, setPincod] = useState("");
  const [productName, setProductName] = useState([]);

  //LoginContext
  const setLoginPending = (isLoginPending) => useState({ isLoginPending });
  const setLoginSuccess = (isLoggedIn) => useState({ isLoggedIn });
  const setLoginError = (loginError) => useState({ loginError });

  const [authState, authDispatch] = useReducer(auth, authInitialState);

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
  };

  const handleClick = (clicked) =>
    setIsClicked({ ...initialState, [clicked]: true });

  //LoginContext
  const login = (email, password) => {
    setLoginPending(true);
    setLoginSuccess(false);
    setLoginError(null);

    fetchLogin(email, password, (error) => {
      setLoginPending(false);

      if (!error) {
        setLoginSuccess(true);
      } else {
        setLoginError(error);
      }
    });
  };

  const logout = () => {
    setLoginPending(false);
    setLoginSuccess(false);
    setLoginError(null);
  };

  return (
    <StateContext.Provider
      value={{
        currentColor,
        currentMode,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
        authState,
        authDispatch,
        login,
        logout,

        auth,
        setAuth,

        banner,
        setBanner,


        token,
        setToken,

        merchantId,
        setMerchantId,

        merId,
        setMerId,

        viewData,
        setViewData,

        value,
        setValue,

        loginData,
        setLoginData,

        profileData,
        setProfileData,
        orderInfo,setOrderInfo,
        orbitMartCommission,
        setOrbitMartCommission,
        pincod,
        setPincod,
        productName,
        setProductName,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export default StateContext;

export const useStateContext = () => useContext(StateContext);
