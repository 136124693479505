import React, { useContext, useEffect, useState } from "react";
import StateContext from "../../contexts/ContextProvider";
import "./ToggleSwitch.css";
import axios from "../api/axiosapi";

export default function ToggleSwitch(props) {
  const [state, setState] = useState(props.item.status);
  const [data, setData] = useState(props.item);

  const okay = 200;

  const { setValue } = useContext(StateContext);

  useEffect(() => {
  }, [state]);

  async function handleChange(e) {

    e.preventDefault();
    const editId = data._id;
    const finalValue = {
      status: !state,
    };
    axios
      .put(`https://api.orbitmart.co.in/api/v1/product/${editId}`, finalValue)
      .then((response) => {
        setState(finalValue.status);
        setValue(okay);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={state} onChange={handleChange} />
      <span className="toggle-switch-slider"></span>
    </label>
  );
}
