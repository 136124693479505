import { Button } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import {Link} from 'react-router-dom'

const AddDelivery = () => {
  const { token } = useContext(StateContext);
  const { merId, setMerId } = useContext(StateContext);
  const [data, setData] = useState([]);

  const { value } = useContext(StateContext);
  const { setValue } = useContext(StateContext);

  const navigate = useNavigate();
  const [deliveryData, setDeliveryData] = useState({
    chargeForOrderAmountBetween_1_199: 0,
    chargeForOrderAmountBetween_200_499: 0,
    chargeForOrderAmountGreaterThan_500: 0,
    perKmChargeOver_5Km: 0,
  });

  const [doPutRequest, setDoPutRequest] = useState(true);

  console.log(deliveryData, "deliveryData")
  // useEffect(()=>{
  //   console.log("Called on delivery data change");
  //   if (prevDeliveryData.chargeForOrderAmountBetween_1_199 !== deliveryData.chargeForOrderAmountBetween_1_199 &&
  //     prevDeliveryData.chargeForOrderAmountBetween_200_499 !== deliveryData.chargeForOrderAmountBetween_200_499 &&
  //     prevDeliveryData.chargeForOrderAmountGreaterThan_500 !== deliveryData.chargeForOrderAmountGreaterThan_500 &&
  //     prevDeliveryData.perKmChargeOver_5Km !== deliveryData.perKmChargeOver_5Km){
  //       setDoPutRequest(false);
  //     }
  // },[deliveryData])

  let prevDeliveryData = {
    chargeForOrderAmountBetween_1_199: 0,
    chargeForOrderAmountBetween_200_499: 0,
    chargeForOrderAmountGreaterThan_500: 0,
    perKmChargeOver_5Km: 0,
  };
  // function fetchOrders() {
  //   if (!merId) {
  //     setMerId(sessionStorage.getItem("id"));
  //   }
  //   axios
  //     .get(
  //       `https://api.orbitmart.co.in/api/v1/deliveryRate/user/${sessionStorage.getItem(
  //         "id"
  //       )}`
  //     )
  //     .then((data) => {
  //       let finalData = data.data.data;
  //       console.log("previous delivery data:-", finalData);
  //       console.log(
  //         "previous delivery data length:-",
  //         finalData[finalData.length - 1]
  //       );

  //       prevDeliveryData = {
  //         chargeForOrderAmountBetween_1_199:
  //           finalData[finalData.length - 1]?.chargeForOrderAmountBetween_1_199,
  //         chargeForOrderAmountBetween_200_499:
  //           finalData[finalData.length - 1]
  //             ?.chargeForOrderAmountBetween_200_499,
  //         chargeForOrderAmountGreaterThan_500:
  //           finalData[finalData.length - 1]
  //             ?.chargeForOrderAmountGreaterThan_500,
  //         perKmChargeOver_5Km:
  //           finalData[finalData.length - 1]?.perKmChargeOver_5Km,
  //       };
  //       console.log("prevDeliveryData-", prevDeliveryData);

  //       setDeliveryData(prevDeliveryData);
  //     })
  //     .catch((e) => console.log("Error Fetching delivery data", e));
  // }

  async function fetchOrders() {
    if (!merId) {
      setMerId(sessionStorage.getItem("id"));
    }
    const config = {
      headers: {
        'x-token': token,
      },
    }
  
    try {
      const response = await axios.get(`https://api.orbitmart.co.in/api/v1/deliveryRate`,config);
  
      const finalData = response.data.data;
      console.log("previous delivery data:-", finalData);
      console.log(
        "previous delivery data length:-",
        finalData[finalData.length - 1]
      );
  
      const prevDeliveryData = {
        chargeForOrderAmountBetween_1_199:
          finalData[0]?.chargeForOrderAmountBetween_1_199,
          // finalData[finalData.length - 1]?.chargeForOrderAmountBetween_1_199,
        chargeForOrderAmountBetween_200_499:
          finalData[0]?.chargeForOrderAmountBetween_200_499,
        chargeForOrderAmountGreaterThan_500:
          finalData[0]?.chargeForOrderAmountGreaterThan_500,
        perKmChargeOver_5Km:
          finalData[0]?.perKmChargeOver_5Km,
      };
      console.log("prevDeliveryData-", prevDeliveryData);
  
      setDeliveryData(prevDeliveryData);
    } catch (e) {
      console.log("Error Fetching delivery data", e);
    }
  }
  

  console.log("SET DELIVERY DATA", deliveryData);
  useEffect(() => {
    fetchOrders();
  }, []);

  const handleChange = (e) => {
    console.log("Onchange:-", e.target.name, e.target.value);
    const check = {
      ...deliveryData,
      [e.target.name]: e.target.value,
    };
    console.log("Checking delivery dat:-", check);
    setDeliveryData({
      ...deliveryData,
      [e.target.name]: e.target.value,
    });
  };

  console.log("DeliveryData:-", deliveryData);

  const updateDeliveryData = () => {
    const uploadData = "https://api.orbitmart.co.in/api/v1/deliveryRate";
    const headers = {
      "Content-Type": "application/json",
      "x-token": token, // Use the correct token value here
    };

    axios
      .put(uploadData, deliveryData, { headers })
      .then((res) => {
        console.log("Successfully updated data", res);
      })
      .catch((error) => {
        console.log("Error updating delivery data", error);
        // Handle the error here, e.g., show an error message to the user
      });
  };

  // const mainPage = () => {
  //   navigate("/deliveryrate");
  // };
  return (
    <div>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
          Delivery Rate
        </h4>

        <form>
          <div class="grid xl:grid-cols-2  m:gap-2">
            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Charge for order amount between 1-199</label>
              <input
                name="chargeForOrderAmountBetween_1_199"
                value={deliveryData?.chargeForOrderAmountBetween_1_199}
                onChange={handleChange}
                type="number"
                class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="deliveryamount"
                placeholder="Amount Charge from Customer"
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Per Km charge over 5Km</label>
              <input
                name="perKmChargeOver_5Km"
                value={deliveryData?.perKmChargeOver_5Km}
                onChange={handleChange}
                type="number"
                class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="deliveryamount"
                placeholder="Amount Charge from Customer"
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label
                for="deliveryamount"
                class="form-label inline-block mb-2 text-gray-700"
              >
                Charge for order amount between 200-499
              </label>
              <input
                name="chargeForOrderAmountBetween_200_499"
                value={deliveryData?.chargeForOrderAmountBetween_200_499}
                onChange={handleChange}
                type="number"
                class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="deliveryamount"
                placeholder="Amount Charge from Customer"
              />
            </div>

            {console.log(deliveryData, "Charge for order amount between")}

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label
                for="deliveryfees"
                class="form-label inline-block mb-2 text-gray-700"
              >
                Charge for order amount greater than 500
              </label>
              <input
                name="chargeForOrderAmountGreaterThan_500"
                value={deliveryData?.chargeForOrderAmountGreaterThan_500}
                onChange={handleChange}
                type="number"
                class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="deliveryfees"
                placeholder="Amount Charge from Customer"
              />
            </div>
          </div>
        </form>
        <div class="flex space-x-2 justify-center">
        {/* <Link to='/'> */}
          <Button onClick={updateDeliveryData} variant="contained">
            Update
          </Button>
        {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default AddDelivery;
