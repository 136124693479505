import * as React from "react";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "./orbitmart-logo.png";
import NewLogo from "../../assets/orbit.png";
// import cors from "cors";
// import { useNavigate } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";

//Login
import { useRef, useState, useEffect, useContext } from "react";
import StateContext from "../../contexts/ContextProvider";
import auth from "../../contexts/reducers/auth";

import { Alert, Stack } from "@mui/material";
import { SentimentVeryDissatisfied } from "@material-ui/icons";
import axios from "axios";
// import axios from "../../api/axios";
// const LOGIN_URL = "/login";

// function Copyright(props) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       <Link color="inherit" href="https://mui.com/"></Link>
//     </Typography>
//   );
// }

const theme = createTheme();

const Register1 = () => {
  const { setAuth } = useContext(StateContext);
  const { auth } = useContext(StateContext);

  const { setLoginData } = useContext(StateContext);
  const { loginData } = useContext(StateContext);

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  console.log(setAuth);
  const userRef = useRef();
  const errRef = useRef();

  let isLoggedIn = false;

  //Token
  const { setToken, setMerId } = useContext(StateContext);

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  // useEffect(() => {
  //   userRef.current.focus;
  // }, []);

  async function storeLogin() {
    const storedItem = JSON.parse(localStorage.getItem("Login_Data"));
    console.log(storedItem);
    if (storedItem) {
      console.log(storedItem);
      let result = await fetch(
        "https://api.orbitmart.co.in/api/v1/auth/employee/merchant/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(storedItem),
        }
      );
      result = await result.json();
      if (result.code == 200) {
        const fullData = result.data;
        const tokens = fullData.token;
        sessionStorage.setItem("merchant_login_data", JSON.stringify(result));
        localStorage.setItem("Login_Data", JSON.stringify(storedItem));
        setLoginData(result);
        console.log("abc", result);
        setToken(tokens);
        setAuth(true);
        console.log(auth);
        let id = result.data._id;
        sessionStorage.setItem("id", id);
        setMerId(id); //here you get your merchantId.based on this id only you got your ordersList
        // setAlert(true);
        let config = {
          headers: {
            "Content-Type": "application/json",
            "x-token": tokens,
          },
        };
        await axios.put(
          `https://api.orbitmart.co.in/api/v1/auth/edit-profile`,
          { WEB_TOKEN: sessionStorage.getItem("FCM_TOKEN") },
          config
        );
      }
    }
  }

  useEffect(() => {
    storeLogin();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get("username"),
  //     password: data.get("password"),
  //   });
  // };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const data = new FormData(e.currentTarget);
      const finalData = {
        username: data.get("username"),
        password: data.get("password"),
        role: "MERCHANT",
      };
      console.log(finalData);
      let result = await fetch(
        "https://api.orbitmart.co.in/api/v1/auth/employee/merchant/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(finalData),
        }
      );
      result = await result.json();
      console.log("LOgin.........RESULT..........", result);
      if (result.code == 200) {
        const fullData = result.data;
        const tokens = fullData.token;
        sessionStorage.setItem("merchant_login_data", JSON.stringify(result));
        localStorage.setItem("Login_Data", JSON.stringify(finalData));
        setLoginData(result);
        console.log("abc", result);
        setToken(tokens);
        setAuth(true);
        console.log(auth);
        let id = result.data._id;
        sessionStorage.setItem("id", id);
        setMerId(id); //here you get your merchantId.based on this id only you got your ordersList
        setAlert(true);
        let config = {
          headers: {
            "Content-Type": "application/json",
            "x-token": tokens,
          },
        };

        await axios.put(
          `https://api.orbitmart.co.in/api/v1/auth/edit-profile`,
          { WEB_TOKEN: sessionStorage.getItem("FCM_TOKEN") },
          config
        );
        // .then((res)=>
        // {
        //   // await axios
        //   // .get(`https://api.orbitmart.co.in/api/v1/auth/get-profile`, config)

        // })

        setTimeout(() => {
          <Link to="/home" />;
        }, 1000);
      } else if (result.code == 201) {
        console.log("Invalid Login Data");
        setError(true);
      }
    } catch (Response) {
      // setAuth(false);
      console.log(Response.message);
    }
  }

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await axios.post(
  //       LOGIN_URL,
  //       JSON.stringify({ username, password }),
  //       {
  //         mode: "cors",
  //         headers: { "Content-Type": "application/json" },
  //         withCredentials: true,
  //       }
  //     );
  //     console.log(JSON.stringify(response?.data));
  //     console.log(JSON.stringify(response));
  //     const accessToken = response?.data?.accessToken;
  //     const role = response?.data?.role;
  //     setAuth({ username, password, role, accessToken });
  //     setUser("");
  //     setPwd("");
  //     setSuccess(true);
  //   } catch (err) {
  //     if (!err?.response) {
  //       setErrMsg("No Server Response");
  //     } else if (err.response?.status === 400) {
  //       setErrMsg("Missing Username or Password");
  //     } else if (err.response?.status === 401) {
  //       setErrMsg("Unauthorized");
  //     } else {
  //       setErrMsg("Login Failed");
  //     }
  //     errRef.current.focus();
  //   }
  // };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        {alert ? (
          <Stack
            sx={{ width: "30%" }}
            style={{
              position: "fixed",
              left: "35%",
              bottom: "5%",
              width: "30%",
            }}
            spacing={2}
          >
            <Alert variant="filled" severity="success">
              Login is Successful
            </Alert>
          </Stack>
        ) : null}
        {error ? (
          <Stack
            sx={{ width: "30%" }}
            style={{
              position: "fixed",
              left: "35%",
              bottom: "5%",
              width: "30%",
            }}
            spacing={2}
          >
            <Alert variant="filled" severity="error">
              Sorry, Invalid Credential
            </Alert>
          </Stack>
        ) : null}
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "25px",
            padding: 2,
            border: "2px solid #003c7e",
          }}
        >
          <img src={NewLogo} />
          <h1
            style={{
              fontFamily: "Helvetica",
              fontWeight: "bold",
              fontSize: "20px",
              color: "#003c7e",
              marginTop: 8,
            }}
          >
            Merchant Login
          </h1>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              type="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            {/*
            {"Don't have a Merchant account yet ?    Sign Up"} */}

            {/* {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item style={{ alignContent: "center" }}>
                {/* <Link to="#" variant="body2">
                  {"Don't have a Merchant account yet ?    Sign Up"}
                </Link>
              </Grid>
            </Grid>  */}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Register1;
