import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_URL;

// console.log("REACT_APP_BACKEND_URL", baseURL);

let headers = {};

// if (localStorage.token) {
//   headers.Authorization = `Bearer ${JSON.parse(sessionStorage.getItem("merchant_login_data")).data.token}`;
// }
if (localStorage.token) {
  const merchantLoginData = JSON.parse(sessionStorage.getItem("merchant_login_data"));
  console.log("Parsed merchant_login_data:", merchantLoginData);

  if (merchantLoginData && merchantLoginData.data && merchantLoginData.data.token) {
    headers.Authorization = `Bearer ${merchantLoginData.data.token}`;
  } else {
    console.error("Token not found in merchant_login_data or invalid data structure.");
  }
}


const axiosInstance = axios.create({
  baseURL: baseURL,
  headers,
});

export default axiosInstance;
