import React, { useState, useContext, useEffect } from "react";
import StateContext from "../../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import blank from "../../assets/blank.jpg";
import { ImCross } from "react-icons/im";
import { BsFillPencilFill } from "react-icons/bs";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
//Material Ui
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  Paper,
  Grid,
  IconButton,
  Avatar,
  Input,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  AppBar,
  Toolbar,
  CircularProgress,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";

const EditProfile = () => {
  //New
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(3),
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    textField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    avatar: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    editIcon: {
      position: "absolute",
      top: 0,
      right: 0,
      backgroundColor: theme.palette.background.paper,
      borderRadius: "50%",
    },
    button: {
      marginRight: theme.spacing(2),
    },
    statusContainer: {
      display: "flex",
      alignItems: "center",
    },
  }));

  const classes = useStyles();

  const { profileData, setProfileData } = useContext(StateContext);
  const { token } = useContext(StateContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(profileData);

  console.log(profileData,"SDFv")

  // const handleChange = (e) => {
  //   const checkPan = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
  //   const checkAccNo = /^[0-9]{9,18}$/;
  //   const checkIfscCode = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  //   const checkPincode = /^[1-9][0-9]{5}$/;
  //   const { name, value } = e.target;
  //   if (name === "panCard") {
  //     if (!checkPan.test(value)) {
  //       setPanCardError(true);
  //     } else {
  //       setPanCardError(false);
  //     }
  //   }
  //   if (name === "accountNo") {
  //     if (!checkAccNo.test(value)) {
  //       setAccountNoError(true);
  //     } else {
  //       setAccountNoError(false);
  //     }
  //   }
  //   if (name === "ifscCode") {
  //     if (!checkIfscCode.test(value)) {
  //       setIfscCodeError(true);
  //     } else {
  //       setIfscCodeError(false);
  //     }
  //   }
  //   if (name === "pincode") {
  //     if (!checkPincode.test(value)) {
  //       setPincodeError(true);
  //     } else {
  //       setPincodeError(false);
  //     }
  //   }
  //   // setData({ ...data, [name]: value });
  //   setData((prevData) => ({ ...prevData, [name]: value }));
  // };


  const handleChange = (e) => {
    const { name, value } = e.target;
  
    const validationRules = {
      panCard: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
      accountNo: /^[0-9]{9,18}$/,
      ifscCode: /^[A-Z]{4}0[A-Z0-9]{6}$/,
      pincode: /^[1-9][0-9]{5}$/,
    };
  
    // Set error states based on validation
    if (validationRules[name]) {
      const isValid = validationRules[name].test(value);
      switch (name) {
        case "panCard":
          setPanCardError(!isValid);
          break;
        case "accountNo":
          setAccountNoError(!isValid);
          break;
        case "ifscCode":
          setIfscCodeError(!isValid);
          break;
        case "pincode":
          setPincodeError(!isValid);
          break;
        default:
          break;
      }
    }
  
    setProfileData((prevData) => ({ ...prevData, [name]: value }));
  };
  
  const [imgnew, setImgNew] = useState([]);

  const navigate = useNavigate();

  function mainPage() {
    setTimeout(() => {
      navigate("/");
    }, 500);
  }

  useEffect(() => {
    // debugger;
    console.log(profileData);
    if (profileData.avatar) {
      // debugger;
      setImgNew(profileData?.avatar[0]);
    }
  }, [profileData]);


  //Image
  const [selectedImages, setSelectedImages] = useState([]);
  const [imgValue, setImageValue] = useState([]);
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [panCardError, setPanCardError] = useState(false);
  const [accountNoError, setAccountNoError] = useState(false);
  const [ifscCodeError, setIfscCodeError] = useState(false);
  const [pincodeError, setPincodeError] = useState(false);
  const [tab, setTab] = useState("personal");
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      // debugger;
      setLoading(true);
      console.log(e.target.files[0]);
      setImageValue(e.target.files[0]);
      const reader = new FileReader();
      reader.onload = (event) => {
        setImgNew(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  //ImagePreview
  const [image, setImage] = React.useState("");
  // const [imgUpload, setImgUpload] = useState(false);
  const [serverImage, setServerImage] = useState([""]);

  function deleteImage(e) {
    const s = image.filter((item, index) => index != e);
    setImage(s);
  }

  console.log(image);

  const ImageThumb = ({ image }) => {
    return (
      <div>
        <img src={URL.createObjectURL(image)} alt={image.name} />
      </div>
    );
  };

  async function postData(e) {
    // debugger;
    const id = data._id;
    const finalToken = token;
    const uploadImage = "https://api.orbitmart.co.in/api/v1/upload";
    const uploadData = "https://api.orbitmart.co.in/api/v1/auth/edit-profile";
    const formData = new FormData();
    if (imgValue.name) {
      formData.append("image", imgValue, imgValue.name);
    }
    setLoading(true);
    axios
      .post(uploadImage, formData)
      .then((res) => {
        // debugger;
        console.log(res);
        const newimg = res.data.files;
        const coverPhoto = newimg[0];
        const finalData = {
          firstName: profileData.firstName,
          lastName: profileData.lastName,
          firmName: profileData.firmName,
          address: profileData.address,
          city: profileData.city,
          country: profileData.country,
          email: profileData.email,
          gender: profileData.gender,
          mobile: profileData.mobile,
          pincode: profileData.pincode,
          state: profileData.state,
          district: profileData.district,
          addharCard: profileData.addharCard,
          panCard: profileData.panCard,
          classification: profileData.classification,
          accountNo: profileData.accountNo,
          bankAccountType: profileData.bankAccountType,
          bankName: profileData.bankName,
          branchName: profileData.branchName,
          ifscCode: profileData.ifscCode,
          avatar: coverPhoto,
        };
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-token": finalToken,
          },
          body: JSON.stringify(finalData),
        };
        fetch(uploadData, requestOptions)
          .then(async (response) => {
            const isJson = response.headers
              .get("content-type")
              ?.includes("application/json");
            const data = isJson && (await response.json());
            console.log(data);
            setAlert(true);
            setTimeout(() => {
              setLoading(false);
              mainPage();
            }, 500);
          })
          .catch((err) => {
            console.log(err);
            setError(true);
            setTimeout(() => {
              setLoading(false);
              mainPage();
            }, 500);
          });
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setTimeout(() => {
          setLoading(false);
          mainPage();
        }, 500);
      });
  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const blueColor = "#003c7e";
  const containedButtonStyle = {
    color: "white",
    backgroundColor: blueColor,
    borderColor: blueColor,
  };
  const outlinedButtonStyle = {
    color: blueColor,
    borderColor: blueColor,
  };

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <AppBar
        position="static"
        style={{ backgroundColor: "#003c7e", borderRadius: "20px" }}
      >
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography variant="h6">Edit Profile</Typography>
          <span class="flex space-x-2 m-4 justify-end">
            <Button
              onClick={() => mainPage()}
              variant="outlined"
              color="inherit"
            >
              Back
            </Button>
          </span>
        </Toolbar>
      </AppBar>
      <Grid container spacing={3} alignItems="center" justify="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <img
            style={{
              width: 150,
              height: 150,
              borderRadius: 150 / 2,
              overflow: "hidden",
              borderWidth: 3,
              borderColor: "rgb(158, 235, 71)", objectFit: 'cover'
            }}

            className="rounded-full h-24 w-24"
            src={imgnew}
            alt="Uploaded Image"
          />
          {/* <Avatar className={classes.avatar} src={imgnew} alt="Uploaded Image" sx={{width: 24, height: 24}} />*/}
          <div className={classes.statusContainer}>
            <IconButton
              aria-label={data?.ActiveInactive ? "Active" : "Inactive"}
            >
              {data?.ActiveInactive ? (
                <React.Fragment>
                  <CheckCircleIcon style={{ color: "green" }} />
                  <span style={{ color: "green", marginLeft: "8px" }}>
                    Active
                  </span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <HighlightOffIcon style={{ color: "red" }} />
                  <span style={{ color: "red", marginLeft: "8px" }}>
                    Inactive
                  </span>
                </React.Fragment>
              )}
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={9}>
          <TextField
            style={{ cursor: 'not-allowed' }}
            label="Firm Name"
            disabled
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={profileData?.firmName}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            style={{ cursor: 'not-allowed' }}
            label="Name"
            disabled
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={profileData?.firstName + " " + profileData?.lastName}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            style={{ cursor: 'not-allowed' }}
            label="Email"
            variant="outlined"
            disabled
            fullWidth
            className={classes.textField}
            value={profileData?.email}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            style={{ cursor: 'not-allowed' }}
            label="Mobile"
            variant="outlined"
            disabled
            fullWidth
            className={classes.textField}
            value={profileData?.mobile}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button
          variant={tab === "personal" ? "contained" : "outlined"}
          className={classes.button}
          onClick={(event) => handleTabChange(event, "personal")}
          style={
            tab === "personal" ? containedButtonStyle : outlinedButtonStyle
          }
        >
          Personal Information
        </Button>
        <Button
          variant={tab === "address" ? "contained" : "outlined"}
          className={classes.button}
          onClick={(event) => handleTabChange(event, "address")}
          style={tab === "address" ? containedButtonStyle : outlinedButtonStyle}
        >
          Address Information
        </Button>
        <Button
          variant={tab === "bank" ? "contained" : "outlined"}
          className={classes.button}
          onClick={(event) => handleTabChange(event, "bank")}
          style={tab === "bank" ? containedButtonStyle : outlinedButtonStyle}
        >
          Bank Information
        </Button>
      </div>
      <form className={classes.form}>
        {tab === "personal" && (
          <Paper className={classes.paper}>
            <Typography variant="h6">Personal Information</Typography>
            <Grid container spacing={3}>
              {/* <Grid item xs={12} sm={6}>
               <TextField
                 label="Email"
                 name="email"
                 variant="outlined"
                 margin="normal"
                 fullWidth
                 value={data.email}
                 InputProps={{
                   readOnly: true,
                 }}
               />
             </Grid>
             <Grid item xs={12} sm={6}>
               <TextField
                 label="Mobile"
                 name="mobile"
                 variant="outlined"
                 margin="normal"
                 fullWidth
                 value={data.mobile}
                 InputProps={{
                   readOnly: true,
                 }}
               />
             </Grid> */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  name="firstName"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={profileData?.firstName}
                  onChange={handleChange}
                  required
                  InputProps={{
                    readOnly: false, // ensure readOnly is not set to true
                  }}
                  error={!profileData?.firstName}
                  helperText={!profileData?.firstName && "Please enter your First name"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={profileData.lastName}
                  onChange={handleChange}
                  required
                  error={!profileData.lastName}
                  helperText={!profileData.lastName && "Please enter your Last name"}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "15px" }}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="select-label">Gender</InputLabel>
                  <Select
                    labelId="select-label"
                    name="gender"
                    value={profileData.gender}
                    onChange={handleChange}
                    label="Gender"
                    required
                    error={!profileData.gender}
                    helperText={!profileData.gender && "Please enter your name"}
                  >
                    <MenuItem value="MALE">Male</MenuItem>
                    <MenuItem value="FEMALE">Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Firm Name"
                  name="firmName"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={profileData.firmName}
                  onChange={handleChange}
                  required
                  error={!profileData.firmName}
                  helperText={!profileData.firmName && "Please enter Firm name"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Pan Card"
                  name="panCard"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={profileData.panCard}
                  onChange={handleChange}
                  required
                  error={!profileData.panCard || panCardError}
                  helperText={
                    (!profileData.panCard && "Please enter pancard Number") ||
                    (panCardError && "Please Enter Valid Pancard Number")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input type="file" onChange={handleImageChange} />
                {imgnew && (
                  <>
                    <br />
                    <img
                      onChange={handleImageChange}
                      style={{
                        width: 150,
                        height: 150,
                        borderRadius: 150 / 2,
                        overflow: "hidden",
                        borderWidth: 2,
                        border: "none", objectFit: 'cover'
                      }}

                      className="rounded-full h-24 w-24"
                      src={imgnew}
                      alt="Uploaded Image"
                    />
                    {/* <Avatar
                      onChange={handleImageChange}
                      src={imgnew}
                      alt="Uploaded Image"
                    style={{ width: "150px", height: "150px" }}
                    /> */}
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
        )}
        {tab === "address" && (
          <Paper className={classes.paper}>
            <Typography variant="h6">Address Information</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Address"
                  name="address"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={profileData.address}
                  onChange={handleChange}
                  required
                  error={!profileData.address}
                  helperText={!profileData.address && "Please enter your address"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="City"
                  name="city"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={profileData.city}
                  onChange={handleChange}
                  required
                  error={!profileData.city}
                  helperText={!profileData.city && "Please enter your city"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Zipcode"
                  name="pincode"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={profileData.pincode}
                  onChange={handleChange}
                  required
                  error={!profileData.pincode || pincodeError}
                  helperText={
                    (!profileData.pincode && "Please enter your pincode") ||
                    (pincodeError && "Please enter valid Pincode")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="District"
                  name="district"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={profileData.district}
                  onChange={handleChange}
                  required
                  error={!profileData.district}
                  helperText={!profileData.district && "Please enter your district"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="State"
                  name="state"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={profileData.state}
                  onChange={handleChange}
                  required
                  error={!profileData.state}
                  helperText={!profileData.state && "Please enter your state"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Country"
                  name="country"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={profileData.country}
                  onChange={handleChange}
                  required
                  error={!profileData.country}
                  helperText={!profileData.country && "Please enter your country"}
                />
              </Grid>
            </Grid>
          </Paper>
        )}
        {tab === "bank" && (
          <Paper className={classes.paper}>
            <Typography variant="h6">Banking Information</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Bank Name"
                  name="bankName"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={profileData.bankName}
                  onChange={handleChange}
                  required
                  error={!profileData.bankName}
                  helperText={!profileData.bankName && "Please enter Bank Name"}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "15px" }}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="select-label">Bank Account Type</InputLabel>
                  <Select
                    labelId="select-label"
                    name="bankAccountType"
                    value={profileData.bankAccountType}
                    onChange={handleChange}
                    label="Bank Account Type"
                    required
                  >
                    <MenuItem value="SAVING">Saving</MenuItem>
                    <MenuItem value="CURRENT">Current</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Account No"
                  name="accountNo"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={profileData.accountNo}
                  onChange={handleChange}
                  required
                  error={!profileData.accountNo || accountNoError}
                  helperText={
                    (!profileData.accountNo && "Please enter Account no") ||
                    (accountNoError && "Please Enter Valid account No")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="IFSC Code"
                  name="ifscCode"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={profileData.ifscCode}
                  onChange={handleChange}
                  required
                  error={!profileData.ifscCode || ifscCodeError}
                  helperText={
                    (!profileData.ifscCode && "Please enter Ifsc Code") ||
                    (ifscCodeError && "Please Enter Valid IFSC Code")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Branch Name"
                  name="branchName"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={profileData.branchName}
                  onChange={handleChange}
                  required
                  error={!profileData.branchName}
                  helperText={!profileData.branchName && "Please enter Branch name"}
                />
              </Grid>
            </Grid>
          </Paper>
        )}

        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={postData}
              className={classes.submitButton}
              style={containedButtonStyle}
            >
              Save Changes
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => mainPage()}
              className={classes.submitButton}
              style={outlinedButtonStyle}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
      <div className="flex space-x-2 justify-end">
        {alert ? (
          <Alert severity="success">
            <strong> Your Profile updated Sucessfully </strong>
          </Alert>
        ) : null}
        {error ? (
          <Stack spacing={2}>
            <Alert severity="error">
              <strong> Error - Update Unsuccessful</strong>
            </Alert>
          </Stack>
        ) : null}
      </div>
    </div>
  );
};

export default EditProfile;
