import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../view.css";
import { ImCross } from "react-icons/im";
import axios from "axios";

import StateContext from "../../contexts/ContextProvider";

const AddVoucher = () => {
  const navigate = useNavigate();

  const mainPage = () => {
    navigate("/voucher");
  };

  const [image, setImage] = useState([""]);
  const [serverImage, setServerImage] = useState([""]);
  const { token } = useContext(StateContext);
  const [voucherData, setVoucherData] = useState({
    name: "",
    validityFrom: "",
    validityUpto: "",
    zipcode: "",
    description: "",
  });

  function uploadSingleImage(e) {
    e.preventDefault();
    setImage([...image, URL.createObjectURL(e.target.files[0])]);
  }

  function imageUpload() {
    debugger;
    const uploadAPI = "https://api.orbitmart.co.in/api/v1/upload";
    const formData = new FormData();
    formData.append("Image", image);
    axios
      .post(uploadAPI, formData)
      .then((res) => {
        console.log(res.data);
        setServerImage(res.data);
      })
      .catch((error) => {
        console.log(error.message);
        console.error("There was an error!", error);
      });
  }
  console.log("Image", image);

  console.log("Server Image", serverImage);

  function deleteImage(e) {
    const s = image.filter((item, index) => index != e);
    setImage(s);
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setVoucherData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const createVoucherAPI = "https://api.orbitmart.co.in/api/v1/voucher";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify(voucherData),
    };

    axios
      .post(createVoucherAPI, voucherData, requestOptions)
      .then((res) => {
        console.log(res.data);
        // Do something with the response if needed
      })
      .catch((error) => {
        console.log(error.message);
        console.error("There was an error!", error);
      });
    mainPage();
  };

  // useEffect(()=> {
  //   handleSubmit()
  // })

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Voucher Info
      </h4>
      <div class="flex space-x-2 justify-end">
        <button type="button" class="backButton" onClick={() => mainPage()}>
          Back
        </button>
        <button type="button" class="addButton" onClick={handleSubmit}>
          Add
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="form-group mb-6 p-2">
            <label
              for="exampleInputEmail1"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="name"
              onChange={handleChange}
              placeholder="Blockbuster Voucher"
            />
          </div>

          <div class="form-group mb-6 p-2">
            <label
              for="validityfrom"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Validity From
            </label>
            <input
              type="date"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="validityfrom"
              onChange={handleChange}
            />
          </div>

          <div class="form-group mb-6 p-2">
            <label
              for="validityupto"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Validity Upto
            </label>
            <input
              type="date"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="validityupto"
              onChange={handleChange}
            />
          </div>

          <div class="form-group mb-6 p-2">
            <label
              for="combo"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Combo Voucher MRP
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="combo"
              value="400"
              disabled="true"
            />
          </div>

          <div class="form-group mb-6 p-2">
            <label
              for="discounted"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Discounted MRP
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="discounted"
              value="500"
              disabled="true"
            />
          </div>

          <div class="form-group mb-6 p-2">
            <label
              for="orbit"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Orbit Mart Commision
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="orbit"
              value="50"
              disabled="true"
            />
          </div>

          <div class="form-group mb-6 p-2">
            <label
              for="zipcode"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Zip Code
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="zipcode"
              onChange={handleChange}
              placeholder="400100"
            />
          </div>

          <div class="form-group mb-6 p-2">
            <label
              for="description"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Description
            </label>
            <textarea
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="description"
              onChange={handleChange}
              placeholder="Details"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            {image.length > 0 &&
              image.map((item, index) => {
                return (
                  <div style={{ display: "inline-block" }} key={item}>
                    <p className="block-icon">
                      {item > 0 ? (
                        <>
                          <img src={item} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteImage(index)}
                            className="icon-tag"
                          />
                        </>
                      ) : (
                        <span></span>
                      )}
                    </p>
                  </div>
                );
              })}
            <input
              accept="image/*"
              disabled={image.length === 5}
              type="file"
              onChange={uploadSingleImage}
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="avatar"
              name="avatar"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <button type="button" class="addButton" onClick={imageUpload}>
              Image Upload
            </button>
          </div>
        </div>
      </form>
      <h5 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Additional Offer Info :
      </h5>
      <form>
        <div class="grid xl:grid-cols-2  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Merchant</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option selected>Select Merchant</option>
              <option value="1">Milk Factory</option>
              <option value="2">A to Z Fashion</option>
              <option value="3">Aditya Services</option>
              <option value="4">French Cafe</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Merchant Item</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option selected>Select Item</option>
              <option value="1">Pasta</option>
              <option value="2">Pizza</option>
              <option value="3">Macroni</option>
              <option value="4">Noodles</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddVoucher;
