import React from "react";
import { useGeolocated } from "react-geolocated";
import { useEffect, useState } from "react";
import axios from "axios";

const Location = () => {
  const API_endpoint = `https://api.openweathermap.org/data/2.5/weather?`;
  const API_key = `d0666bfe307719a88f6d23cbb14ff0df`;

  const [city, setCity] = useState();

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      userDecisionTimeout: 5000,
    });

  useEffect(() => {
    if (coords != null) {
      debugger;
      console.log(coords);
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords);
      });
      let latitude = coords.latitude;
      let longitude = coords.longitude;
      let finalAPIEndPoint = `${API_endpoint}lat=${latitude}&lon=${longitude}&appid=${API_key}`;
      axios.get(finalAPIEndPoint).then((response) => {
        debugger;
        setCity(response.data.name);
        console.log(response.data);
      });
    }
  }, [coords]);

  console.log(city);

  return !isGeolocationAvailable ? (
    <div>Your browser does not support Geolocation</div>
  ) : !isGeolocationEnabled ? (
    <div>Geolocation is not enabled</div>
  ) : coords ? (
    <table>
      <tbody>
        <tr>
          <td>latitude</td>
          <td>{coords.latitude}</td>
        </tr>
        <tr>
          <td>longitude</td>
          <td>{coords.longitude}</td>
        </tr>
        <tr>
          <td>altitude</td>
          <td>{coords.altitude}</td>
        </tr>
        <tr>
          <td>heading</td>
          <td>{coords.heading}</td>
        </tr>
        <tr>
          <td>speed</td>
          <td>{coords.speed}</td>
        </tr>
        <tr>
          <td>city</td>
          <td>{city}</td>
        </tr>
      </tbody>
    </table>
  ) : (
    <div>Getting the location data&hellip; </div>
  );
};

export default Location;
