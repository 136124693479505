import React, { useState } from "react";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import useStyle from "./styles";
import Input from "./Input";
import { Link } from "react-router-dom";
import "./style.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import logo from "./orbitmart-logo.png";
import { useNavigate } from "react-router-dom";
// import AddAdmin from "../addAdmin/AddAdmin";

const initialState = {
  email: "",
  password: "",
};

const panels = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "distributor",
    label: "Distributor",
  },
  {
    value: "merchant",
    label: "Merchant",
  },
];

const Auth = () => {
  const classes = useStyle();
  const [formData, setFormData] = useState(initialState);
  const [panel, setPanel] = useState("admin");

  const handleChange = (e) => {
    setPanel(e.target.value);
  };
  // const [showPassword, setShowPassword] = useState(false);
  // const [isSignup, setIsSignup] = useState(false);
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // // const isSignup = true;

  // const handleShowPassword = () => setShowPassword(!showPassword);

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   console.log("formdata == ", formData)
  //   if (isSignup) {
  //     dispatch(signup(formData, navigate));
  //   } else {
  //     dispatch(signin(formData, navigate));
  //   }
  // }

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value })
  // }

  // const switchMode = () => {
  //   setIsSignup((prevIsSignup) => !prevIsSignup);
  //   setShowPassword(false);
  // }

  // const handleSubmit = (e) => {
  //   console.log(e.password.value);
  //   if (e.email.value === "admin@gmail.com" || e.password.value === "admin") {
  //     console.log(e.password.value);
  //     console.log(e.email.value);
  //   } else {
  //     console.log("Wrong Id and Password");
  //   }
  // };

  const clickHandle = (e) => {
    console.log(e.password.value);
    console.log(e.email.value);
  };

  // const registerPage = () => {
  //   const navigate = useNavigate();
  //   navigate("/addadmin", <AddAdmin />);
  //   isLoggedIn(true);
  // };

  return (
    <div class="box">
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      />
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper} elevation={3}>
          <img
            src={logo}
            style={{ marginTop: "13px", height: "90px" }}
            alt="orbitmart merchant"
          />

          <div style={{ alignItems: "right", marginTop: 10 }}></div>
          <div class="justify-end">
            {/* <TextField
              id="outlined-select-currency"
              select
              label="Login For"
              value={panel}
              onChange={handleChange}
            >
              {panels.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField> */}
          </div>
          <Typography variant="h5"></Typography>
          <form className={classes.form}>
            <Grid container spacing={2}>
              {/* {
              isSignup && (
                <>
                  <Input 
                    name = "firstname" 
                    label = "First Name" 
                    handleChange = {handleChange} 
                    autoFocus 
                    half 
                  />
                  <Input 
                    name = "lastname" 
                    label = "Last Name" 
                    handleChange = {handleChange}  
                    half 
                  />
                </>
              )
            } */}
              <Input
                name="email"
                label="Email Address"
                type="email"
                value={(e) => email(e.target.value)}
              />
              <Input
                name="password"
                label="Password"
                type="password"
                value={(e) => password(e.target.value)}
              />
              {/* { isSignup && 
              <Input 
                name="confirmPassword" 
                label="Repeat Password" 
                handleChange={handleChange} 
                type="password" 
              /> 
            } */}
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => clickHandle()}
            >
              Sign In
            </Button>
            {/* <div style={{ alignContent: "center" }}>New To Panel</div> */}

            <Grid container justify="flex-end">
              <Grid item>
              
              <Link href="#" variant="body2">
               {"Don't have a Merchant account yet ?    Sign Up"}
             </Link>
           
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default Auth;


// import React, { useState } from "react";
// import {
//   Avatar,
//   Button,
//   Paper,
//   Grid,
//   Typography,
//   Container,
// } from "@material-ui/core";
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import useStyle from "./styles";
// import Input from "./Input";

// // import "./style.css";
// import Link from "@mui/material/Link";

// import Box from "@mui/material/Box";
// import TextField from "@mui/material/TextField";
// import MenuItem from "@mui/material/MenuItem";
// import logo from "./orbitmart-logo.png";
// import { useNavigate } from "react-router-dom";
// import { Alert } from "@mui/material";
// import { useRef,useEffect, useContext } from "react";
// import StateContext from "../../contexts/ContextProvider";




// const panels = [
//   {
//     value: "distributor",
//     label: "Distributor",
//   },
//   {
//     value: "merchant",
//     label: "Merchant",
//   },
// ];

// const Auth = () => {
//   const { setAuth } = useContext(StateContext);
//   console.log(setAuth);
//   const userRef = useRef();
//   const errRef = useRef();

//   let isLoggedIn = false;

//   const [user, setUser] = useState("");
//   const [pwd, setPwd] = useState("");
//   const [errMsg, setErrMsg] = useState("");
//   const [success, setSuccess] = useState(false);

//   // useEffect(() => {
//   //   userRef.current.focus;
//   // }, []);

//   useEffect(() => {
//     setErrMsg("");
//   }, [user, pwd]);
// }

//   const handleSubmit =()=>{
//     e.preventDefault();
//      {
//       const data = new FormData(e.currentTarget);
//       const finalData = {
//         username: data.get("username"),
//         password: data.get("password"),
//         role: "ADMIN",
//       };
//       console.log(finalData);
//       let result = fetch(
//         "https://api.orbitmart.co.in/api/v1/auth/admin/login",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//           },
//           body: JSON.stringify(finalData),
//         }
//       );
//       result = result.json();
//       console.log(result);
//       setAuth(true);
//       if ((setAuth = true)) {
//         <Link to="#" />;
//       }
//       else
//      ((setauth=false))
//     {
//       Alert("pass or mail is wrong")
      
//     }
  
    
        
//       // if(
//       //   email==="admin@gmail.com" && password ==="admin"
//       //   )
//       //   {
//       //     alert("login secces");
//       //   // console.log("email is "+ Email);
//       //   // console.log( "Password is"+ Password);
        
//       //   // navigate('/employees');
//       //   // <link to ="/employees"/>;
//       //   //   const admin = false;
//       //   }
//       //   else
//       //   {
//       //   //  navigate("/login");
//       //    alert("email and password wrong");
//       //     //  <link to="/login"/>;
//       //   }
//        };
    
//   return (
//     <div class="boxes">
//       <Box
//         component="form"
//         sx={{
//           "& .MuiTextField-root": { m: 1, width: "100ch" },
//         }}
//         noValidate
//         autoComplete="off"
//       />
//       <Container component="main" maxWidth="xs">
//         <Paper className={classes.paper} elevation={3}>
//           <img
//             src={logo}
//             style={{ marginTop: "13px", height: "90px" }}
//             alt="orbitmart merchant"
//           />

//           <div style={{ alignItems: "right", marginTop: 10 }}></div>
//           <div class="justify-end">
//             <TextField
//               id="outlined-select-currency"
//               select
//               label="Login For"
//               value={panel}
//               onChange={handleChange}
//             >
//               {panels.map((option) => (
//                 <MenuItem key={option.value} value={option.value}>
//                   {option.label}
//                 </MenuItem>
//               ))}
//             </TextField>
//           </div>
//           <Typography variant="h5"></Typography>
//           <form className={classes.form}>
//             <Grid container spacing={2}>
//               {/* {
//               isSignup && (
//                 <>
//                   <Input 
//                     name = "firstname" 
//                     label = "First Name" 
//                     handleChange = {handleChange} 
//                     autoFocus 
//                     half 
//                   />
//                   <Input 
//                     name = "lastname" 
//                     label = "Last Name" 
//                     handleChange = {handleChange}  
//                     half 
//                   />
//                 </>
//               )
//             } */}
//               <Input
//                 name="email"
//                 label="Email Address"
//                 type="email"
//                 value={(e) => email(e.target.value)}
//               />
//               <Input
//                 name="password"
//                 label="Password"
//                 type="password"
//                 value={(e) => password(e.target.value)}
//               />
//               {/* { isSignup && 
//               <Input 
//                 name="confirmPassword" 
//                 label="Repeat Password" 
//                 handleChange={handleChange} 
//                 type="password" 
//               /> 
//             } */}
//             </Grid>
//             <Button
//               fullWidth
//               variant="contained"
//               color="primary"
//               className={classes.submit}
//               onClick={() => handleSubmit()}
//             >
//               Sign In
//             </Button>
//             {/* <div style={{ alignContent: "center" }}>New To Panel</div> */}
//             <Grid item>
//               <Link href="#" variant="body2">
//                 {"Don't have a Merchant account yet ?    Sign Up"}
//               </Link>
//             </Grid>
//           </form>
//         </Paper>
//       </Container>
//     </div>
//   );
// };

// export default Auth;




// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Avatar, Button, Paper, Grid, Typography, Container } from '@material-ui/core';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
// import useStyle from './styles';
// import Input from './Input';
// //import { convertStringToValue } from '@syncfusion/ej2/maps';

// // const initialState = {
// //   // firstName: '', 
// //   // lastName: '', 
// //   email: 'Email',
// //   password: 'Password',
// //   // confirmPassword: '' 
// // };


// export default function Auth () {
//   const [email,setEmail]=useState("");
//   const [password,setPassword]=useState("");

  
  

//   const classes = useStyle();
//   // const [formData, setFormData] = useState(initialState);
//   // const [showPassword, setShowPassword] = useState(false);
//   // const [isSignup, setIsSignup] = useState(false);
//   // const dispatch = useDispatch();
//   // const navigate = useNavigate();

//   // // const isSignup = true;

//   // const handleShowPassword = () => setShowPassword(!showPassword);

//   // const handleSubmit = (e) => {
//   //   e.preventDefault();

//   //   console.log("formdata == ", formData)
//   //   if (isSignup) {
//   //     dispatch(signup(formData, navigate));
//   //   } else {
//   //     dispatch(signin(formData, navigate));
//   //   }
//   // }

//   // const handleChange = (e) => {
//   //   setFormData({ ...formData, [e.target.name]: e.target.value })
//   // }

//   // const switchMode = () => {
//   //   setIsSignup((prevIsSignup) => !prevIsSignup);
//   //   setShowPassword(false);
//   // }
 
//   const handleSubmit =()=>{
//    console.log("pranit");

    
//   if(
//      email=== 'admin@gmail.com' && password  === 'admin'
//     )
//     {
//       alert("login secces");
//     // console.log("email is "+ Email);
//     // console.log( "Password is"+ Password);
    
//     // navigate('/employees');
//     // <link to ="/employees"/>;
//     //   const admin = false;
//     }
//     else
//     {
//     //  navigate("/login");
//      alert("email and password wrong");
//       //  <link to="/login"/>;
//     }
//    };

//   return (
//     <Container component="main" maxWidth="xs">
//       <Paper className={classes.paper} elevation={3}>
//         <Avatar className={classes.avatar}>
//           <LockOutlinedIcon />
//         </Avatar>
//         <Typography variant="h5">
//         </Typography>
//         <form className={classes.form}>
//           <Grid container spacing={2}>
//             {/* {
//               isSignup && (
//                 <>
//                   <Input 
//                     name = "firstname" 
//                     label = "First Name" 
//                     handleChange = {handleChange} 
//                     autoFocus 
//                     half 
//                   />
//                   <Input 
//                     name = "lastname" 
//                     label = "Last Name" 
//                     handleChange = {handleChange}  
//                     half 
//                   />
//                 </>
//               )
//             } */}
//             <Input
//               name="email"
//               label="email"
//               value={email}
//               onChange={e=>setEmail(e.target.value)}
//               type="email"
//             />
//             <Input
//               name="password"
//               onChange={e=>setPassword(e.target.value)}
//               label="Password"
//               value={password}
//               type="password"
//             />
//             {/* { isSignup && 
//               <Input 
//                 name="confirmPassword" 
//                 label="Repeat Password" 
//                 handleChange={handleChange} 
//                 type="password" 
//               /> 
//             } */}
//           </Grid>
//           <Button
//             type="submit"
//             onClick={handleSubmit}

//             fullWidth
//             variant="contained"
//             color="primary"
//             className={classes.submit}
//           >
//             Sign In
//           </Button>

//           {/* <Grid container justify="flex-end">
//             <Grid item>

//             </Grid> */}
//           {/* </Grid> */}
//         </form>
//       </Paper>
//     </Container>
//   )
// };












