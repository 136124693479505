import React, { useEffect, useState } from "react";
import './App.css'
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { createBrowserHistory } from "history";
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  Merchant,
  Calendar,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Line,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
  // TooltipComponent,
  // page,
  // Inject
} from "./pages";
import "./App.css";

// Authentication
import Auth from "./pages/login/Login";
import Register1 from "./pages/Ragister/Register1";
import { useNavigate } from "react-router-dom";

//MainPage
import ManageItem from "./pages/manageItem/ManageItem";
import Orders from "./pages/orders/Orders";
import Employees from "./pages/employee/Employees";
import Voucher from "./pages/voucher/Voucher";
import Invoice from "./pages/invoice/Invoice";
// import DeliveryRate from "./pages/deliveryRate/DeliveryRate";
import Offers from "./pages/merchantOffer/Offers";
import Banner from "./pages/banner/Banner";
import Service from "./pages/Service/Service"
import AddService from "./pages/Service/Addservice";

import { useStateContext } from "./contexts/ContextProvider";

//login
import Login from "./pages/login/Login";
import RegisterForm from "./pages/Ragister/RegisterForm";
import Location from "./pages/location/Location";

import ConfirmOrder from "./pages/orders/ConfirmOrder";

//AddPanels
import AddManageItem from "./pages/manageItem/AddManageItem";
import AddEmployee from "./pages/employee/AddEmployee";
import AddVoucher from "./pages/voucher/AddVoucher";
import AddInvoice from "./pages/invoice/AddInvoice";
import AddDelivery from "./pages/deliveryRate/AddDelivery";
import AddMerchantType from "./pages/merchantType/AddMerchantType";
import AddBanner from "./pages/banner/AddBanner";
import AddOffer from "./pages/merchantOffer/AddOffer";
import AssignOrder from "./pages/orders/AssignOrder";
//ViewPabels
import ViewManageItem from "./pages/manageItem/ViewManageItem";
import ViewEmployee from "./pages/employee/ViewEmployee";
import ViewOrder from "./pages/orders/ViewOrder";
import ViewVoucher from "./pages/voucher/ViewVoucher";

//EditPanels

import EditManageItem from "./pages/manageItem/EditManageItem";
import EditOrder from "./pages/orders/EditOrder";
import EditEmployee from "./pages/employee/EditEmployee";
import EditVoucher from "./pages/voucher/EditVoucher";
import EditMerchantOffer from "./pages/merchantOffer/EditMerchantOffer";
import EditProfile from "./pages/editProfile/EditProfile";
import EditOffer from "./pages/merchantOffer/EditOffer";
import EditBanner from "./pages/banner/EditBanner";
import Notification from "../src/components/Notification"
import {getToken, onMessageListener } from '../src/firebaseInit'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
    auth,
    setAuth,
    token,
  } = useStateContext();

  const userLogin = () => {
    const newData = sessionStorage.getItem("merchant_login_data");
    if (newData != null) {
      setAuth(true);
    }
  };

  const history = createBrowserHistory();

  function refreshLogin() {
    if (token == null || undefined) {
      <Link to={"/"} />;
      window.sessionStorage.clear();
      window.location.reload();
    }
  }

  const [token_fcm, setToken] = useState('');
  const [message, setMessage] = useState('');

    const fetchToken = async () => {
      const currentToken = await getToken();
      console.log(currentToken, "fire-token");
      setToken(currentToken);
      sessionStorage.setItem("FCM_TOKEN", token_fcm)
    };

    const handleMessage = async () => {
      const payload = await onMessageListener();
      console.log("payload", payload)
      const notificationBar = `<b>${payload.notification.title}</b>: ${payload.notification.body}`
      toast.info(notificationBar, { autoClose: 2000, closeOnClick: true,});
      setMessage(payload);
    };

    fetchToken();
    handleMessage(); 

    console.log('message', message)

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  useEffect(() => {
    userLogin();

  }, [token]);

  


  return (
    <div>
      {!auth ? (
        <Register1 />
      ) : (
        <>
          {/* className={CurrentMode === "Dark" ? "dark:" : ""} */}
          <div className="dark:bg-main-dark-bg">
            <BrowserRouter history={history}>
              <div className="flex relative dark:bg-main-dark-bg">
                <div
                  className="fixed right-4 bottom-4"
                  style={{ zIndex: "1000" }}
                >
                  <TooltipComponent content="Settings" position="Top">
                    {/* <button
                      type="button"
                      onClick={() => setThemeSettings(true)}
                      style={{ background: currentColor, borderRadius: "50%" }}
                      className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                    >
                      <FiSettings />
                    </button> */}
                  </TooltipComponent>
                </div>
                {activeMenu ? (
                  <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                    <Sidebar />
                  </div>
                ) : (
                  <div className="w-0 dark:bg-secondary-dark-bg">
                    <Sidebar />
                  </div>
                )}
                <div
                  style={{ height: "0" }}
                  className={
                    activeMenu
                      ? "dark:bg-main-dark-bg bg-white min-h-screen md:ml-72 w-full  "
                      : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
                  }
                >
                  <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                    <Navbar />
                  </div>
                  <div>
                    {themeSettings && <ThemeSettings />}

                    <Routes>
                      {/* dashboard  */}
                      <Route path="/" element={<Merchant />} />
                      <Route path="/home" element={<Merchant />} />

                      {/* authentication */}
                      <Route path="/login" element={<Auth />} />
                      <Route path="/register" element={<Register1 />} />

                      {/* pages  */}
                      <Route path="/Notification" element={<Notification />} />
                      <Route path="/manageItem" element={<ManageItem />} />
                      <Route path="/orders" element={<Orders />} />
                      <Route path="/employees" element={<Employees />} />
                      <Route path="/customers" element={<Customers />} />
                      <Route path="/voucher" element={<Voucher />} />
                      <Route path="/invoice" element={<Invoice />} />
                      <Route path="/deliveryrate" element={<AddDelivery />} />
                      <Route path="/offers" element={<Offers />} />
                      <Route path="/banner" element={<Banner />} />
                      <Route path="/Service" element={<Service />} />

                      <Route path="/location" element={<Location />} />
                      {/*<Route path="/MerchantType" element={<MerchantType />} />*/}

                      <Route
                        path="/Orders/ConfirmOrder"
                        element={<ConfirmOrder />}
                      />

                      <Route path="/login" element={<Login />} />
                      <Route path="/registerForm" element={<RegisterForm />} />

                      {/* AddPath  */}
                      <Route
                        path="/manageItem/add"
                        element={<AddManageItem />}
                      />
                      <Route path="/employees/add" element={<AddEmployee />} />
                      <Route path="/voucher/add" element={<AddVoucher />} />
                      <Route path="/invoice/add" element={<AddInvoice />} />

                      <Route
                        path="/MerchantType/add"
                        element={<AddMerchantType />}
                      />
                      <Route path="/banner/add" element={<AddBanner />} />
                      <Route path="/offers/add" element={<AddOffer />} />
                      <Route path="/Addservice" element={<AddService />} />

                      {/* ViewPath  */}
                      <Route
                        path="/manageItem/view/:id"
                        element={<ViewManageItem />}
                      />
                      <Route path="/Employee/view" element={<ViewEmployee />} />
                      <Route path="/Order/view/:id" element={<ViewOrder />} />
                      <Route
                        path="/Voucher/view/:id"
                        element={<ViewVoucher />}
                      />
                      <Route
                        path="/orders/assign/:id"
                        element={<AssignOrder />}
                      />

                      {/* EditPath  */}
                      <Route
                        path="/manageItem/edit/:id"
                        element={<EditManageItem />}
                      />
                      <Route path="/orders/edit" element={<EditOrder />} />
                      <Route
                        path="/employees/edit"
                        element={<EditEmployee />}
                      />
                      <Route path="/voucher/edit" element={<EditVoucher />} />
                      {/* <Route
                        path="/deliveryrate/edit"
                        element={<EditDelivery />}
                      /> */}
                      <Route
                        path="/merchantoffer/edit"
                        element={<EditMerchantOffer />}
                      />
                      <Route path="/editprofile" element={<EditProfile />} />
                      <Route path="/offers/edit" element={<EditOffer />} />
                      <Route
                        path="/banners/edit/:id"
                        element={<EditBanner />}
                      />
                    </Routes>
                  </div>
                  <div>
                    <ToastContainer
                      position="bottom-right"
                      autoClose={2000}
                      hideProgressBar
                      newestOnTop={false}
                      closeOnClick={true}
                      rtl={false}
                      draggable
                      pauseOnHover
                      theme="colored"
                      // transition="Bounce"
                    />
                  </div>
                  <Footer />
                </div>
              </div>
            </BrowserRouter>
          </div>
        </>
      )}
    </div>
  );
};
// })
export default App;
