import { FiEye } from "react-icons/fi";
import { BsFillPencilFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import DeleteModel from "../DeleteModel";
import ConfirmModal from "../ConfirmModel";
import CancelModel from "../CancelModel";
var OrderStatusBg = "#ff5c8e";

const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{ background: "#ff5c8e" }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.orderStatus}
  </button>
);

const gridConfirm = (props) => (
  <div class="flex space-x-2 justify-center">
    <h6
      style={{ color: "#03c9d7", display: "flex" }}
      class="font-medium leading-tight text-3xl mt-0 mb-2 "
    >
      {/* <ConfirmModal /> */}
      {props.orderStatus == "PENDING" && <ConfirmModal props={props} />}
      {/* {props.orderStatus == "REJECTED" && <ConfirmModal props={props} />}
      {props.orderStatus == "PROCESSING" && <ConfirmModal props={props} />} */}
     
      {
        props.orderStatus == "REJECTED" &&
        <button
        style={{ color: "white", backgroundColor: "#ff1a1a" }}
        type="button"
        class="px-6
    py-2.5
    bg-blue-600
    text-white
    font-medium
    text-xs
    leading-tight
    uppercase
    rounded
    shadow-md
    hover:bg-blue-700 hover:shadow-lg
    focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
    active:bg-blue-800 active:shadow-lg
    transition
    duration-150
    ease-in-out"
      >
        cancelled
      </button>}
      
   

{
  props.orderStatus !== "REJECTED" &&
  props.orderStatus !== "PENDING" &&
  <button
  style={{ color: "white", backgroundColor: "#8be78b" }}
  type="button"
  class="px-6
py-2.5
bg-blue-600
text-white
font-medium
text-xs
leading-tight
uppercase
rounded
shadow-md
hover:bg-blue-700 hover:shadow-lg
focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
active:bg-blue-800 active:shadow-lg
transition
duration-150
ease-in-out"
>
  confirmed
</button>}

</h6>
  </div>
);



const gridCancel = () => (
  <div class="flex space-x-2 justify-center">
    <h6
      style={{ color: "#03c9d7", display: "flex" }}
      class="font-medium leading-tight text-3xl mt-0 mb-2 "
    >
      <CancelModel />
      {/*<Link to="">
       
        <button
          style={{ color: "white", backgroundColor: "#ff5c8e" }}
          type="button"
          class="px-6
      py-2.5
      bg-blue-600
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out"
        >
          Cancel
        </button>
</Link> */}
    </h6>
  </div>
);

const gridOrderEdit = (itemId) => (
  <div className="flex items-center justify-center gap-2">
    {/* <h1
      style={{ color: "#8be78b", display: "flex" }}
      className="font-small leading-tight text-xl mt-0 mb-2 "
    >
      <Link style={{ marginRight: 2 }} to="/orders/edit">
        <BsFillPencilFill />
      </Link>
    </h1> */}
    <h1
      style={{ color: "#03c9d7", display: "flex" }}
      className="font-small leading-tight text-xl mt-0 mb-2 "
    >
      <Link style={{ marginLeft: 2, marginRight: 2 }} to={`/Order/view/${itemId._id}`}>
        <FiEye />
      </Link>
    </h1>
    <h1
      style={{ color: "#ff5c8e", display: "flex" }}
      className="font-small leading-tight text-xl mt-0 mb-2 "
    >
      <DeleteModel itemId={itemId}  />
      {/* <Link to="/orders/orderid">
          <button
            style={{ color: "white", backgroundColor: "#8be78b" }}
            type="button"
            class="px-6
        py-2.5
        bg-blue-600
        text-white
        font-medium
        text-xs
        leading-tight
        uppercase
        rounded
        shadow-md
        hover:bg-blue-700 hover:shadow-lg
        focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
        active:bg-blue-800 active:shadow-lg
        transition
        duration-150
        ease-in-out"
          >
            Confirm
          </button>
        </Link> */}
    </h1>

    {/* <h1
        style={{ color: "#ff5c8e", display: "flex" }}
        className="font-small leading-tight text-xl mt-0 mb-2 "
      >
        <Link style={{ marginLeft: 2, marginRight: 2 }} to="">
          <AiFillDelete/>
        </Link>
      </h1> */}
    <h1 />
  </div>
);

const gridDate = (props) => {
  const d1 = new Date(props.createdAt);
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  function formatTime(date) {
    var d = new Date(date),
      hour = "" + d.getUTCHours(),
      minute = "" + d.getUTCMinutes(),
      second = d.getUTCSeconds();

    if (minute.length < 2) minute = "0" + minute;
    if (second.length < 2) second = "0" + second;

    return [hour, minute, second].join(":");
  }

  var dateresult = formatDate(d1);
  var timeresult = formatTime(d1);
  return (
    <button
      type="button"
      style={{ backgroundColor: "03C9D7" }}
      className="text-black py-1 px-2 capitalize rounded-2xl text-md"
    >
      {dateresult}
      <br />
      {timeresult}
    </button>
  );
};

function dataChange(props) {
  var data = new Date(props.createdAt);
  return data;
}

export const orderGrid = [
  {
    field: "orderNo",
    headerText: "OrderNo",
    width: "Auto",
    textAlign: "Center",
  },
  {
    field: "createdAt",
    headerText: "Order Date",
    width: "Auto",
    textAlign: "Center",
    // template: gridDate,
    type:'date',
     format:"E,MMM dd yyyy,hh:mm a"
  },
  {
    field: "totalPrice",
    headerText: "Order Amount",
    width: "Auto",
    textAlign: "Center",
  },
  {
    field: "paymentStatus",
    headerText: "Order Status",
    width: "Auto",
    textAlign: "Center",
    template: gridOrderStatus,
  },
  {
    field: "userId.firstName",
    headerText: "Client Name",
    width: "Auto",
    textAlign: "Center",
  },
  {
    field: "confirm",
    headerText: "Order Action",
    textAlign: "Center",
    width: "Auto",
    template: gridConfirm,
  },
  // {
  //   field: "cancel",
  //   headerText: "Cancel",
  //   textAlign: "Center",
  //   width: "Auto",
  //   template: gridCancel,
  // },
  {
    field: "action",
    headerText: "Action",
    width: "Auto",
    textAlign: "Center",
    template: gridOrderEdit,
  },
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

export const orderData = [
  {
    orderno: "#F2021053022017",
    orderdate: "mon, 31 july 2022",
    orderamount: "₹ 200",
    OrderStatus: "Pending",
    clientname: "Varun Apte",
    OrderStatusBg: "#ff5c8e",
  },
  {
    orderno: "#F2021053022017",
    orderdate: "mon, 15 Aug 2022",
    orderamount: "₹ 315",
    OrderStatus: "Pending",
    clientname: "Brijesh Mohan",
    OrderStatusBg: "#ff5c8e",
  },
];
