import React from "react";
import { useNavigate } from "react-router-dom";

const AddManageItem = () => {
  const navigate = useNavigate();

  const mainPage = () => {
    navigate("/manageitem");
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <h1 class="font-medium  flex space-x-2 justify-center aline-center leading-tight text-4xl  mt-0 mb-2 text-blue-600">
        Sign Up
      </h1>
      <h4 class="flex space-x-2 justify-center aline-center">
      Enter your details to create your account:
      </h4 >
      <br/>
      <br/>
      {/* <div class="flex space-x-2 justify-end">
        <button
          type="button" onClick={() => mainPage()} class="backButton"
         >
          Back
        </button>
        <button
          type="button" class="addButton">
         
        
          Add
        </button>
      </div> */}

      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Type</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              {/* <option selected>Restraunt</option> */}
              <option value="1">Retailer</option>
              <option value="2">wholesaler</option>
              <option value="3">manifacturer</option>
              {/* <option value="4">The Second Wife</option> */}
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Category</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option value="1">Veg hotel</option>
              <option value="2">mobile shop</option>
              <option value="3">mobile shop</option>
              <option value="4">mobile shop</option>
              <option value="5">mobile shop</option>
              <option value="6">mobile shop</option>
              <option value="7">mobile shop</option>
              <option value="8">mobile shop</option>
              <option value="9">mobile shop</option>

            </select>
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Classification</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option selected>Add On</option>
              <option value="1">Chapati</option>
              <option value="2">Chicken Wings</option>
              <option value="3">Extra Cheese</option>
              <option value="4">Salad</option>
            </select>
          </div> */}

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Sub Classification</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option selected>Bevrages</option>
              <option value="1">Coke</option>
              <option value="2">Pepsi</option>
              <option value="3">ThumbsUp</option>
              <option value="4">Butter Milk</option>
            </select>
          </div> */}

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Group Category</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option selected>Aata & Other Floor</option>
              <option value="1">Bakery</option>
              <option value="2">Body Lotion & Oil</option>
              <option value="3">Bread & Egg</option>
              <option value="4">Cat Food</option>
            </select>
          </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Brand Name</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option selected>Amul</option>
              <option value="1">Bingo</option>
              <option value="2">Lays</option>
              <option value="3">Balaji</option>
              <option value="4">Maggie</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="name"
              class="form-label inline-block mb-2 text-gray-700"
            >
              First Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="name"
              placeholder="Enter first Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="name"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Last Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="Last name"
              placeholder="Enter last Name"
            />
          </div>


          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="name"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Address
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="Address"
              placeholder="Enter address"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="name"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Company name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="Company Name"
              placeholder="Enter company name"
            />
          </div>



          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="name"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Zip code
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="Zip code"
              placeholder="Enter zip code"
            />
          </div>


          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="name"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Mobile Number
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="Mobile Number"
              placeholder="Enter number"
            />
          </div>


          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="name"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Password
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="Password"
              placeholder="password"
            />
          </div>


          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="name"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Confirm Password
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="Password"
              placeholder="Confirm password"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="name"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Email
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="Email"
              placeholder="Email"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>cuntry</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option selected>india</option>
              <option value="1">pakistan</option>
              <option value="2">UK</option>
              <option value="3">pranit</option>
              <option value="4">USA</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>state</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option selected>Maharashtra</option>
              <option value="1">MP</option>
              <option value="2">UP</option>
              <option value="3">telengana</option>
              <option value="4">AP</option>
            </select>
          </div>


          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Gender</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >

              <option value="1">male</option>
              <option value="2">female</option>
              <option value="3">other</option>
              
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Bank name</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >

              <option value="1">SBI BANK</option>
              <option value="2">CANERA BANK</option>
              <option value="3">ANDRA BANK</option>
              
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Branch</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >

              <option value="1">chandrapur</option>
              <option value="2">nagpur</option>
              <option value="3">bhopal</option>
              
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Acc typr</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >

              <option value="1">saving acc</option>
              <option value="2">current acc</option>
              <option value="3">other</option>
              
            </select>
          </div>


          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="name"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Bank Acc Number
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="Bank Acc Number"
              placeholder="Enter Acc Number"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="name"
              class="form-label inline-block mb-2 text-gray-700"
            >
              IFCE code
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="code"
              placeholder="Enter IFCE code"
            />
          </div>

          {/* <div class="flex space-x-2 justify-center aline-center">
            <button type="button"   class="backButton" onClick={handleClose}>
              Cancel
                </button>
            <button type="button" class="addButton">
              sign in
            </button>
          </div> */}
<br/>
<br/>

<div class="flex space-x-2 justify-center  aline-center">
        <button
          type="button"  class="backButton"
         >
          Cancel
        </button>
        <button
          type="button" class="addButton">
         
        <h1>
          sign up
          </h1>
        </button>
      </div>
      </div>
      </form>
    </div>
  );
};

export default AddManageItem;
