import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState, useContext } from "react";
import axios from "../api/axiosapi";
import StateContext from "../../contexts/ContextProvider";
import { Navigate } from "react-router-dom";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function ProductToggle(props) {
  const [state, setState] = useState(props.status);
  const [data, setData] = useState(props.tDatas);

  // console.log(data);
  const okay = 200;

  var status = "";
  const { setValue } = useContext(StateContext);

  async function handleChange(e) {
    console.log(state);
    if (state === true) {
      setState(false);
      status = false;
    } else {
      setState(true);
      status = true;
    }
    // state ? setState(false) : setState(true);
    console.log(state);
    e.preventDefault();
    const editId = data._id;
    console.log(state);
    axios
      .put(`https://api.orbitmart.co.in/api/v1/product/${editId}`, {
        status,
      })
      .then((response) => console.log(response.data))
      .catch((error) => {
        console, log(error.message);
        console.error("There was an error!", error);
      });
    setValue(okay);
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <IOSSwitch sx={{ m: 1 }} checked={state} onChange={handleChange} />
        }
      />
    </FormGroup>
  );
}
