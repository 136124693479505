import React, { useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import axios from "axios";
import StateContext from "../../contexts/ContextProvider";
import { useEffect } from "react";
import blank from "../../assets/blank.jpg";
import { BsFillPencilFill } from "react-icons/bs";
import { Alert, Stack } from "@mui/material";
import "../view.css";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
//DropdownNew
import Select from "react-select";

const ITEM_HEIGHT = 98;
const ITEM_PADDING_TOP = 23;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddOffer = () => {
  const [data, setData] = useState([""]);
  const [tDatas, setTDatas] = useState([]);
  const [values, setvalues] = useState([]);

  const { token } = useContext(StateContext);
  const { setValue } = useContext(StateContext);
  const { merId } = useContext(StateContext);
  const { pincod } = useContext(StateContext);
  console.log(merId);
  console.log(pincod);
  console.log({ data }, { token }, { tDatas });


  const [pValue, setPValue] = useState([]);
  const [discountTypeSelect, setDiscountTypeSelect] = useState();
  const [unitQuantitys, setUnitQuantitys] = useState([""]);
  const [sortedProducts, setSortedProducts] = useState([]);

  const navigate = useNavigate();
  console.log({ tDatas })
  function mainPage() {
    navigate("/offers");
  }

  useEffect(() => {
    fetchUnitQuantity();
    fetchSpecificProducts();
    // if (data.unitQuantity != undefined) {
    // }
    // }, [pValue, data.unitQuantity]);
  }, [pValue]);


  console.log(tDatas);

  // function handleChange(e) {
  //   const { name, value } = e.target;
  //   setData({ ...data, [name]: value });
  // }

  const handleChangeForFix = (e) => {
    const { name, value } = e.target;

    if (name === 'offerDiscount') {
      let discountValue = parseFloat(value);

      if (isNaN(discountValue) || discountValue < 0) {
        discountValue = "";
      }
      setData((prevData) => ({
        ...prevData,
        [name]: discountValue,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
  
    if (name === 'offerDiscount') {
      let discountValue = parseFloat(value);
  
      if (isNaN(discountValue) || discountValue < 0) {
        discountValue = "";
      } else if (discountValue > 100) {
        discountValue = 100;
      }
  
      setData((prevData) => ({
        ...prevData,
        [name]: discountValue,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  }
  

  console.log({ data }, data.unitQuantity)
  function fetchSpecificProducts() {
    // debugger;
    // const id = data.unitQuantity
    const id = merId
    const headers = {
      "x-token": token,
    };
    // .get(`https://api.orbitmart.co.in/api/v1/product/byUnitQuantity/${id}`, {
    axios.get(`https://api.orbitmart.co.in/api/v1/product/byMerchant/${id}`, {
      // axios.get(`https://api.orbitmart.co.in/api/v1/product/byUnitQuantity/${id}`, {
      headers,
    })
      .then((res) => {
        // debugger
        console.log(res)
        setTDatas(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      });
  }

  function fetchUnitQuantity() {
    axios
      .get(`https://api.orbitmart.co.in/api/v1/unitQuantity`)
      .then((res) => {
        console.log(res.data);
        setUnitQuantitys(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const productsOp = tDatas.map((opt) => ({
    label: opt.itemName,
    value: opt._id,
  }));

  const handleDropdownChange = (selectedItems) => {
    console.log(selectedItems);
  };

  console.log(productsOp);

  const [productsName, setProductsName] = React.useState([]);

  // for (let i = 0; i < tDatas.length; i++) {
  //   const productsList = [tDatas.itemName[i]];
  // }

  // for (let i = 0; i < tDatas.length; i++) {
  //   abc = [tDatas.itemName[i]];
  // }

  console.log(productsName);

  const handleChanges = (event) => {
    const {
      target: { value },
    } = event;
    setProductsName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [mDatas, setMDatas] = useState([]);

  const handleId = (e) => {
    debugger;
    let index = e.target.selectedIndex;
    let arr = e.target.childNodes[index].id;
    if (document.getElementById(arr).style.backgroundColor === "skyblue") {
      document.getElementById(arr).style.backgroundColor = "";
      setMDatas(data.filter((item) => item !== arr));
    } else {
      document.getElementById(arr).style.backgroundColor = "skyblue";
      setMDatas((data) => [...data, arr]);
    }
    console.log(data);
  };

  console.log(data);
  console.log(pincod);

  const dropDownData = [];

  {
    tDatas.map((datas) => dropDownData.push([datas.itemName, datas._id]));
  }

  const [datas, setDatas] = useState([]);

  console.log(dropDownData);

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  console.log(pValue);
  console.log("pincode", pincod);

  function postData() {
    // debugger;
    if (
      data.offerDiscount &&
      data.offerTypes &&
      data.name &&
      data.description &&
      // data.unitQuantity &&
      discountTypeSelect
    ) {
      var productsList = [];
      for (let i = 0; i < pValue.length; i++) {
        productsList.push(pValue[i]);
      }
      const uploadData = "https://api.orbitmart.co.in/api/v1/offer";
      console.log(productsList);
      const finalData = {
        offerType: data.offerTypes,
        name: data.name,
        description: data.description,
        offerDiscount: data.offerDiscount,
        discountType: discountTypeSelect,
        pincode: pincod,
        productId: productsList,
        // unitQuantityId: data.unitQuantity,
        merchantId: merId,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: JSON.stringify(finalData),
      };
      fetch(uploadData, requestOptions)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            setAlert(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          } else {
            setError(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          }
        })
        .catch((err) => {
          setError(true);
          setTimeout(() => {
            mainPage();
          }, 1000);
        });
    }
  }

  const valuesF = [];

  for (let k = 0; k < tDatas.length; k++) {
    valuesF.push(tDatas[k].itemName);
  }

  console.log(valuesF);

  function gridImage(tDatas) {
    return (
      <div>
        <img
          className="rounded-circle h-10 w-10 md:ml-0"
          src={tDatas.image}
          alt="img"
        />
        <span style={{ marginLeft: 25 }}>{tDatas.itemName}</span>
        <span style={{ marginLeft: 25 }}>{tDatas.itemPrice}</span>
      </div>
    );
  }

  function gridImageFilter(tDatas) {
    return (
      <div>
        {tDatas.filter(data.unitQuantity == tDatas.unitQuantityId.title)}
        <img
          className="rounded-circle h-10 w-10 md:ml-0"
          src={tDatas.image}
          alt="img"
        />
        <span style={{ marginLeft: 25 }}>{tDatas.itemName}</span>
        <span style={{ marginLeft: 25 }}>{tDatas.itemPrice}</span>
      </div>
    );
  }

  function gridHeader() {
    return (
      <div className="header">
        <span style={{ marginLeft: 15, fontSize: 15 }} className="columnHeader">
          Image
        </span>
        <span style={{ marginLeft: 10, fontSize: 15 }} className="columnHeader">
          Product Name
        </span>
        <span style={{ marginLeft: 20, fontSize: 15 }} className="columnHeader">
          Price
        </span>
      </div>
    );
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <h4 className="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Add Offers
      </h4>
      <div className="flex space-x-2 justify-end">
        <button
          type="button"
          className="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
      </div>
      <form>
        <div className="grid xl:grid-cols-3  m:gap-2">
          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Offer Types</label>
            <select
              required
              className="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="offerTypes"
              value={data.offerTypes}
              onChange={handleChange}
            >
              <option selected>Select Deal</option>
              <option value="TODAY">Today's Deal</option>
              <option value="BEST">Best Deals</option>
            </select>
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Offer Name</label>
            <input
              required
              type="text"
              className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="name"
              value={data.name}
              onChange={handleChange}
              placeholder="Enter Offer Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Description</label>
            <input
              required
              type="text"
              className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="description"
              value={data.description}
              onChange={handleChange}
              placeholder="Enter Description"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Discount Type</label>
            <select
              required
              name="discountType"
              value={discountTypeSelect}
              onChange={(e) => setDiscountTypeSelect(e.target.value)}
              className="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option selected>Select Value</option>
              <option value="PERCENT">Percentage</option>
              <option value="FIXED">Fixed</option>
            </select>
          </div>

          {discountTypeSelect == "FIXED"
            ? (
              <div className="relative z-0 p-2 w-full mb-6 group">
                <label>Offer Discount</label>
                <input
                  required
                  type="number"
                  className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  name="offerDiscount"
                  value={data.offerDiscount}
                  onChange={handleChangeForFix}
                  placeholder="Enter Discount"
                />
                <p class="peer-invalid:visible text-red-700 font-light">
                    Discount*
                  </p>
              </div>
            )
            :
            (
              <div className="relative z-0 p-2 w-full mb-6 group">
                <label>Offer Discount</label>
                {console.log("Offer", data)}
                <input
                  required
                  type="number"
                  className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  name="offerDiscount"
                  value={data.offerDiscount > 100 ? 100 : data.offerDiscount}
                  min="0"
                  max="100"
                  onChange={handleChange}
                  placeholder="Enter Discount"
                />
                <p class="peer-invalid:visible text-red-700 font-light">
                  Discount (0 to 100)%
                </p>
              </div>
            )
          }




          {/* 
          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Unit Quantity</label>
            <select
              required
              className="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="unitQuantity"
              value={data.unitQuantity}
              onChange={handleChange}
            >
              <option selected>Select Value</option>
              {unitQuantitys.map((typeget) => (
                <option value={typeget._id} id={typeget._id}>
                  {typeget.title}
                </option>
              ))}
            </select>
          </div> */}

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Products</label>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="products"
              id="products"
              onChange={handleId}
            >
              <option selected>Select Value</option>
              {tDatas.map((typeget) => (
                <option value={typeget._id} id={typeget._id}>
                  {typeget.itemName}
                </option>
              ))}
            </select>
          </div> */}

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Products</label>
            <DropDownListComponent
              placeholder="Select a Product"
              dataSource={tDatas}
              itemTemplate={gridImage}
              headerTemplate={gridHeader}
              fields={{ value: "_id", text: "itemName" }}
              multiple
            />
          </div> */}
          {console.log({ tDatas })}
          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Products</label>
            <MultiSelectComponent
              placeholder="Select a Product"
              dataSource={tDatas}
              itemTemplate={gridImage}
              headerTemplate={gridHeader}
              fields={{ value: "_id", text: "itemName" }}
              onChange={(e) => setPValue(e.target.value)}
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Products</label>
            <Select
              options={productsOp}
              onChange={(opt) => setPValue(opt)}
              isMulti
            />
          </div> */}
        </div>
      </form>

      <div className="flex space-x-2 justify-end items-center">
        {alert ? (
          <Stack spacing={2}>
            <Alert variant="filled" severity="success">
              Offer Added Successfully
            </Alert>
          </Stack>
        ) : null}
        {error ? (
          <Stack spacing={2}>
            <Alert variant="filled" severity="error">
              Sorry, Data Cannot be Added at this moment. Please try Again!
            </Alert>
          </Stack>
        ) : null}
        {error1 ? (
          <Stack spacing={2}>
            <Alert variant="filled" severity="error">
              Please, Fill All The Fields
            </Alert>
          </Stack>
        ) : null}
        <button
          type="button"
          className="addButton"
          onClick={postData}
          style={{ width: "8%" }}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddOffer;
