import { createSlice } from '@reduxjs/toolkit';

const initialOfferState = {offer: null};

const offerSlice = createSlice({
    name: 'offer',
    initialState: initialOfferState,
    reducers: {
      addOffer: (state, action) => {
        // Add a new offer to the state
        state.offer = action.payload
      },
      // You can add more reducers here if needed
    },
  });
  
  export const { addOffer } = offerSlice.actions;
  
//   export const selectOffers = (state) => state.offer;
  
  export default offerSlice.reducer;