import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { Link } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import "../../pages/view.css";
import { BsFillPencilFill } from "react-icons/bs";
import blank from "../../assets/blank.jpg";
import { ImCross } from "react-icons/im";

const ViewManageItem = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const { viewData } = useContext(StateContext);
  console.log(viewData);

  const [updatedValue, setUpdatedValue] = useState(viewData);

  function mainPage() {
    navigate("/manageItem");
  }

  //image
  const [selectedImages, setSelectedImages] = useState([]);

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  return (
    <div>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <h4 className="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
          View Item
        </h4>
        <div className="flex space-x-2 justify-end">
          <button
            type="button"
            className="backButton"
            style={{
              alignItems: "center",
              alignContent: "center",
              width: "8%",
            }}
            onClick={() => mainPage()}
          >
            Back
          </button>
        </div>
        <form>
          <div className="grid xl:grid-cols-3  m:gap-2">
            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Domain Type</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="domainType"
                value={updatedValue?.merchantTypeId?.domainType}
                disabled
                not-allowed
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Category</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="title"
                value={updatedValue?.categoryId?.title}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Classification</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="title"
                value={updatedValue?.classificationId?.title}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Sub Classification</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="title"
                value={updatedValue?.subClassificationId?.title}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Group Category</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="title"
                value={updatedValue?.groupCategoryId?.title}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Item Name</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="itemName"
                value={updatedValue.itemName}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Brand Name</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="title"
                value={updatedValue?.brandNameId?.title}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Unit Quantity</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="unitQuantity"
                value={updatedValue?.unitQuantityId?.title}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Net Weight</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="netWeight"
                value={updatedValue.netWeight}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Unit Per Quantity</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="unitPerQuantity"
                value={updatedValue.unitPerQuantity}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Item Price</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="number"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="itemPrice"
                value={updatedValue.itemPrice}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Discount Type</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="discountType"
                value={updatedValue.discountType}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>GST</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="number"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="gst"
                value={updatedValue.gst}
                disabled
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Orbit Mart Commision Type </label>
            <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
    name="orbitMartCommissionType"
    value={updatedValue.orbitMartCommissionType}
    disabled
              />
          </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Orbit Mart Commission</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="orbitMartCommission"
                value={updatedValue.orbitMartCommission}
                // value={updatedValue.merchantId.orbitMartCommission}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Customer Price</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="number"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="customerPrice"
                value={updatedValue.customerPrice}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Merchant Price</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="number"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="merchantPrice"
                value={updatedValue.merchantPrice}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Stock Quantity</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="stockQuantity"
                value={updatedValue.stockQuantity}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Customer Discount</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="text"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="customerDiscount"
                value={updatedValue.customerDiscount}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label>Description</label>
              <input
              style={{cursor: 'not-allowed'}}
                type="textarea"
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="description"
                value={updatedValue.description}
                disabled
              />
            </div>

            <div className="relative z-0 p-2 w-full mb-6 group">
              <label
                for="description"
                className="form-label inline-block mb-2 text-gray-700"
              >
                Image
              </label>
              <div>
                {updatedValue.image ? (
                  <img src={updatedValue.image} />
                ) : (
                  <img src={blank} />
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewManageItem;
